import { padEnd } from "lodash";
import React from "react"

import ReactApexChart from "react-apexcharts"

const chartapex = (props) => {
  const series = [
    { name: "Delivery of Routes Above MTR(%)", data: [58.33, 63.33, 66.67, 63.33] },
    { name: "Delivery Ratio of Routes Under MTR", data: [20.38, 17.94, 14.80, 17.35] },
    { name: "FakeDLR Ratio of Routes Above MTR", data: [21.67, 16.67, 13.33, 16.67] },
    { name: "FakeDLR Ratio of Routes Below MTR", data: [39.62, 42.06, 45.20, 42.65] },
  ]

  let maxYValue = 0;
  
  if (props.chartData){
    for (var Obj of props.chartData) {
        if (Obj.name=="Delivery ratio - routes above MTR (%)") {
          for( var data1 of Obj.data){
            if(data1 > maxYValue) {
              maxYValue = data1
              
            }
          }
        }
        if (Obj.name=="Delivery ratio - routes below MTR (%)") {
          for( var data2 of Obj.data){
            if(data2 > maxYValue) {
              maxYValue = data2
              
            }
          }
        }
        if (Obj.name=="Fake DLR ratio - routes above MTR (%)") {
          for( var data3 of Obj.data){
            if(data3 > maxYValue) {
              maxYValue = data3
              
            }
          }
        }
        if (Obj.name=="Fake DLR ratio - routes below MTR (%)") {
          for( var data4 of Obj.data){
            if(data4 > maxYValue) {
              maxYValue = data4
              
            }
          }
        }
        // console.log("Max value out of all MTR data : " + maxYValue);
    }
  }
  //const kpiData = props.chartData?props.chartData:[];
  //var dataCheck = kpiData.length==0?series:kpiData.mtr_chart_data;  /* deliverd, not deliverd, kpi prop have tale length ? */
  //var chartData = dataCheck?.length==0?series:dataCheck; /* kpireports have tale length ? */

  const options = {
    chart: { zoom: { enabled: false }, toolbar: { show: false } },
    colors: ["#1DAD61", "#1DAD61", "#FB4735", "#FB4735"],
    dataLabels: { enabled: false },
    stroke: { 
      width: [3, 3, 3, 3], 
      curve: "straight",
      dashArray: [0, 4, 0, 4] // Dashed lines for Fake DLR series
    },
    title: { text: "MTR Ratios", align: "left" },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: props.dates,
      title: { text: "Month" },
    },
    yaxis: { 
      title: { text: "%" }, 
      labels: {
        formatter: function (value) {
          return value ? value.toFixed(2) + "%" : "0%";
        }
      },
      min: 0.00, 
      max: (maxYValue + maxYValue / 10),
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 5,
      offsetX: -15,
      show: true,
      markers: {
      customHTML: [
        function () {
          return '<div style="width: 55px;  height: 3px; background-color: #1DAD61; margin-right:2px;"></div>';
        }, // Solid green line
        function () {
          return '<div style="width: 55px; height: 3px; border-top: 2px dashed #1DAD61; margin-right:2px;"></div>';
        }, // Dashed green line
        function () {
          return '<div style="width: 55px; height: 3px; background-color: #FB4735; margin-right:2px;"></div>';
        }, // Solid red line
        function () {
          return '<div style="width: 55px; height: 3px; border-top: 2px dashed #FB4735; margin-right:2px;"></div>';
        }, // Dashed red line
      ],
    },
    },
    responsive: [
      {
        breakpoint: 600,
        options: { chart: { toolbar: { show: false } }, legend: { show: false } },
      },
    ],
  };
  

  return (
    <ReactApexChart
      options={options}
      series={props.chartData?props.chartData:series}
      type="line"
      height="380"
      // className="apexcharts-canvas apexchartscq310u9c apexcharts-theme-light"
    />
  )
}

export default chartapex