import React, { useState } from "react"
import { Link } from "react-router-dom"
import jwt from "jsonwebtoken"
import { FaChevronDown } from "react-icons/fa" // Font Awesome dropdown arrow

const NavbarContent = () => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.error("Error decoding token:", error)
      return null
    }
  }

  // Retrieve token from localStorage and decode it
  const token = localStorage.getItem("authUser")
  const decodedToken = token ? decodeToken(JSON.parse(token)) : null
  const role = decodedToken?.role

  // State to track active dropdown
  const [activeDropdown, setActiveDropdown] = useState(null)

  // Toggle dropdown menu
  const toggleDropdown = menu => {
    setActiveDropdown(activeDropdown === menu ? null : menu)
  }

  const isActive = path => location.pathname === path

  return (
    <div className="d-flex align-items-center horizontal-menu tablet-nav-bar">
      {/* Upload Files - Main Menu with Submenu */}
      {[2, 3, 5].includes(role) && (
        <div className="menu-item">
          <div className="menu-link d-flex justify-content-between align-items-center">
            <Link
              to="/source-upload"
              className="flex-grow-1 pe-1 effect"
              style={{
                color: isActive("/source-upload") ? "#8e292b" : "#333333",
              }}
            >
              Upload Files
            </Link>
            <FaChevronDown
              className={`ml-2 cursor-pointer effect ${
                activeDropdown === "sourceUpload" ? "rotate-up" : "rotate-down"
              }`}
              onClick={e => {
                e.stopPropagation() // Prevent link navigation
                toggleDropdown("sourceUpload")
              }}
            />
          </div>
          {activeDropdown === "sourceUpload" && (
            <ul className="submenu">
              <li>
                <Link
                  to="/upload-files"
                  style={{
                    color: isActive("/upload-files") ? "#8e292b" : "#333333",
                  }}
                >
                  Uploaded Batches
                </Link>
              </li>
              <li>
                <Link
                  to="/reports/history/import"
                  style={{
                    color: isActive("/reports/history/import")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Historical Data Import
                </Link>
              </li>
              <li>
                <Link
                  to="/smsc-inspector"
                  style={{
                    color: isActive("/smsc-inspector") ? "#8e292b" : "#333333",
                  }}
                >
                  SMSC Inspector
                </Link>
              </li>
            </ul>
          )}
        </div>
      )}

      {/* Generate Report - Direct Link */}
      {[2, 3, 5].includes(role) && (
        <div className="menu-item">
          <Link
            to="/reports/generate"
            className="menu-link"
            style={{
              color: isActive("/reports/generate") ? "#8e292b" : "#333333",
            }}
          >
            Generate Report
          </Link>
        </div>
      )}

      {/* Edit Report - Direct Link */}
      {[2, 3, 5].includes(role) && (
        <div className="menu-item">
          <Link
            to="/reports/edit"
            className="menu-link"
            style={{ color: isActive("/reports/edit") ? "#8e292b" : "#333333" }}
          >
            Edit Report
          </Link>
        </div>
      )}

      {[4].includes(role) &&(
        <div className="menu-items">
          <Link to="/reports/edit" className="menu-link"
           style={{ color: isActive("/reports/edit") ? "#8e292b" : "#333333" }}
          >
            Reports
          </Link>
        </div>
      )}

      {/* Analytics - Main Menu with Submenu */}
      {[3, 4, 5].includes(role) && (
        <div className="menu-item">
          <div
            className="menu-link d-flex justify-content-between align-items-center effect"
            onClick={e => {
              e.stopPropagation()
              toggleDropdown("analytics")
            }}
          >
            Analytics{" "}
            <FaChevronDown
              className={`ms-1 ml-2 cursor-pointer effect ${
                activeDropdown === "analytics" ? "rotate-up" : "rotate-down"
              }`}
            />
          </div>
          {activeDropdown === "analytics" && (
            <ul className="submenu">
              <li>
                <Link
                  to="/analytics/nptresultoverview"
                  style={{
                    color: isActive("/analytics/nptresultoverview")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  NPT Result Overview
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics/gatekeeperfakedlr"
                  style={{
                    color: isActive("/analytics/gatekeeperfakedlr")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Gatekeeper Fake DLR
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics/gatekeeperbypass"
                  style={{
                    color: isActive("/analytics/gatekeeperbypass")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Gatekeeper Bypass
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics/Bypassroute"
                  style={{
                    color: isActive("/analytics/Bypassroute")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Bypass Route
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics/senderidcount"
                  style={{
                    color: isActive("/analytics/senderidcount")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Sender ID Count
                </Link>
              </li>
            </ul>
          )}
        </div>
      )}

      {/* Customer Portal - Direct Link */}
      {[1, 2, 5].includes(role) && (
        <div className="menu-item">
          <Link
            to="/dashboard"
            className="menu-link"
            style={{ color: isActive("/dashboard") ? "#8e292b" : "#333333" }}
          >
            {role === 1 ? "Dashboard" : "Customer Portal"}
          </Link>
        </div>
      )}

      {[1].includes(role) && (
        <div className="menu-item">
          <div
            className="menu-link d-flex justify-content-between align-items-center effect"
            onClick={e => {
              e.stopPropagation()
              toggleDropdown("analytics")
            }}
          >
            Analytics{" "}
            <FaChevronDown
              className={`ms-1 ml-2 cursor-pointer effect ${
                activeDropdown === "analytics" ? "rotate-up" : "rotate-down"
              }`}
            />
          </div>
          {activeDropdown === "analytics" && (
            <ul className="submenu">
              <li>
                <Link
                  to="/analytics/customernptresultoverview"
                  style={{
                    color: isActive("/analytics/customernptresultoverview")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  NPT Result Overview
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics/customerbypassroute"
                  style={{
                    color: isActive("/analytics/customerbypassroute")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Bypass Route
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics/customersenderidcount"
                  style={{
                    color: isActive("/analytics/customersenderidcount")
                      ? "#8e292b"
                      : "#333333",
                  }}
                >
                  Sender ID Count
                </Link>
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default NavbarContent
