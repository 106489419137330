import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import Select from "react-select"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { DateRangePicker, Stack } from "rsuite"
import { useRef } from "react"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import { useGetCustomerSenderIdCountMutation } from "store/analytics/analytics.api"
import { setSenderIdCount } from "store/analytics/analytics.slice"
import { useDispatch, useSelector } from "react-redux"
import { useGetTemplatesQuery } from "store/template/template.api"
import { setTemplates } from "store/template/template.slice"
import { get } from "lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { DateRangePicker } from "rsuite"
import "rsuite/DateRangePicker/styles/index.css"
import toastr from "toastr"
import { debounce } from "lodash"
import SortableHeader from "components/Common/Sort"
import { useGetCustomerOperatorCountriesQuery } from "store/country/country.api"
import { setCountries } from "store/country/country.slice"
import { useLazyGetCustomerOperatorsByCountryQuery } from "store/operator/operator.api"
import { setOperators } from "store/operator/operator.slice"
import { components } from "react-select"
import { Toggle } from "rsuite"
import "rsuite/Toggle/styles/index.css"
import {
  selectFilter,
  Comparator,
  textFilter,
} from "react-bootstrap-table2-filter"

const predefinedBottomRanges = [
  {
    label: "Last 3 Months",
    value: [
      new Date(new Date().setMonth(new Date().getMonth() - 3)),
      new Date(),
    ],
  },
  {
    label: "Last 6 Months",
    value: [
      new Date(new Date().setMonth(new Date().getMonth() - 6)),
      new Date(),
    ],
  },
  {
    label: "Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
  },
  {
    label: "This Year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
  },
  {
    label: "Q1",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 2, 31),
    ],
  },
  {
    label: "Q2",
    value: [
      new Date(new Date().getFullYear(), 3, 1),
      new Date(new Date().getFullYear(), 5, 30),
    ],
  },
  {
    label: "Q3",
    value: [
      new Date(new Date().getFullYear(), 6, 1),
      new Date(new Date().getFullYear(), 8, 30),
    ],
  },
  {
    label: "Q4",
    value: [
      new Date(new Date().getFullYear(), 9, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
  },
  {
    label: "Last 5 Years",
    value: [new Date(new Date().getFullYear() - 5, 0, 1), new Date()],
  },
  {
    label: "All Time",
    value: [
      new Date(0), // This sets the date to the earliest possible date in JavaScript
      new Date(),
    ],
  },
]

export default function CustomerAnalyticsSenderID() {
  const { SearchBar } = Search
  const [searchText, setSearchText] = useState("")
  const [selectedXTest, setXTest] = useState("")
  const [selectedCountry, setCountry] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [tableColumns, setTableColumns] = useState([])
  const [selectedTemplate, setTemplate] = useState("")
  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const sixMonthsAgo = new Date()
  const currentDate = new Date()
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 3)

  const [startDate, setStartDate] = useState(formatDate(sixMonthsAgo))
  const [endDate, setEndDate] = useState(formatDate(currentDate))
  const dispatch = useDispatch()

  const [sortOrder, setSortOrder] = useState("asc")
  const [sortOrderFirst, setSortOrderFirst] = useState("asc")
  const [sortOrderCountry, setSortOrderCountry] = useState("asc")
  const [sortOrderOperator, setSortOrderOperator] = useState("asc")
  const [sortOrderCountrySubscriber, setSortOrderCountrySubscriber] =
    useState("asc")
  const [sortOrderOperatorSubscriber, setSortOrderOperatorSubscriber] =
    useState("asc")
  const [sortOrderMTR, setSortOrderMTR] = useState("asc")
  const [sortOrdertotalDelivery, setSortOrdertotalDelivery] = useState("asc")
  const [sortOrderAmountOfTests, setSortOrderAmountOfTests] = useState("asc")
  const [shortCode, setShortCode] = useState(false)
  const [smscOwnerOptions, setSmscOwnerOptions] = useState([])
  const [dateSetting, setDateSetting] = useState(false)

  const handleSortChange = newSortOrder => {
    setSortOrder(newSortOrder)
  }
  const handleSortChangeFirst = newSortOrder => {
    setSortOrderFirst(newSortOrder)
  }
  const handleSortChangeCountry = newSortOrder => {
    setSortOrderCountry(newSortOrder)
  }
  const handleSortChangeOperator = newSortOrder => {
    setSortOrderOperator(newSortOrder)
  }
  const handleSortChangeCountrySubscriber = newSortOrder => {
    setSortOrderCountrySubscriber(newSortOrder)
  }
  const handleSortChangeOperatorSubscriber = newSortOrder => {
    setSortOrderOperatorSubscriber(newSortOrder)
  }
  const handleSortChangeMTR = newSortOrder => {
    setSortOrderMTR(newSortOrder)
  }
  const handleSortChangetotalDelivery = newSortOrder => {
    setSortOrdertotalDelivery(newSortOrder)
  }
  const handleSortChangeAmountOfTests = newSortOrder => {
    setSortOrderAmountOfTests(newSortOrder)
  }

  const { data: countriesNew, isLoading: countriesLoading } =
    useGetCustomerOperatorCountriesQuery()
  const { countries: country } = useSelector(state => state.countryNew)

  const sortedCountries = [...country].sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  const [
    fetchOperators,
    {
      data: operatorsNew,
      isLoading: operatorsLoading,
      isSuccess: operatorLoadSuccess,
    },
  ] = useLazyGetCustomerOperatorsByCountryQuery()
  const { operators: operator } = useSelector(state => state.operatorNew)

  const [filteredResults, setFilteredResults] = useState([])
  const [senderID, { data: senderIDdata, isLoading: senderIDfetching }] =
    useGetCustomerSenderIdCountMutation({})
  const { senderIDcount } = useSelector(state => state.analyticsSlice)

  useEffect(() => {
    dispatch(setSenderIdCount([]))
    setSmscOwnerOptions([])
    setFilteredResults([])
    if (
      selectedXTest &&
      !dateSetting &&
      ((startDate && endDate) || (!startDate && !endDate))
    ) {
      senderID({
        lastXtests: selectedXTest,
        operator_id: selectedOperator,
        country_id: selectedCountry,
        startDate: startDate,
        endDate: endDate,
        shortCode: shortCode,
      })
        .unwrap()
        .then(data => {
          // console.log(data);
          dispatch(setSenderIdCount(data))

          // Create a set of unique smsc_owner values, handling null or undefined with a fallback to "Empty"
          let uniqueSmscOwners = new Set(
            data?.data?.sender_id_count?.map(item => item.smsc_owner || "Empty")
          )

          // Convert the set to an array and then to an object with indices as keys and smsc_owner values as values
          let smscOwnerOptions = Array.from(uniqueSmscOwners).reduce(
            (acc, value, index) => {
              // Handle empty strings or null values uniformly as "Empty"
              acc[index] = value === "" ? "Empty" : value
              return acc
            },
            {}
          )

          // Set the smscOwnerOptions state or use it as needed
          setSmscOwnerOptions({ smscOwner: smscOwnerOptions }) // Assuming you have a setter function to update state or similar
        })
        .catch(error => {
          console.log(error)
          toastr.error(error)
        })
    }
  }, [
    selectedXTest,
    selectedOperator,
    selectedCountry,
    startDate,
    endDate,
    shortCode,
    dateSetting,
  ])

  // const columns2 = [
  //   {
  //     dataField: "total_count",
  //     text: "Count",
  //     text: (
  //       <SortableHeader
  //         title="Count"
  //         sortOrder={sortOrderCountry}
  //         onSortChange={handleSortChangeCountry}
  //       />
  //     ),
  //     sort: true,
  //     headerClasses: "country-header",
  //   },
  //   {
  //     dataField: "operator_name",
  //     text: "Network",
  //     text: (
  //       <SortableHeader
  //         title="Network"
  //         sortOrder={sortOrderOperator}
  //         onSortChange={handleSortChangeOperator}
  //       />
  //     ),
  //     sort: true,
  //     headerClasses: "country-header",
  //   },
  //   {
  //     dataField: "bypass_ratio",
  //     text: "Bypass Ratio",
  //     headerClasses: "id-header",
  //   },
  //   {
  //     dataField: "delivery_ratio_above_mtr",
  //     text: "Delivery Above MTR",
  //     headerClasses: "id-header",
  //   },
  //   {
  //     dataField: "delivery_ratio_below_mtr",
  //     text: "Delivery Below MTR",
  //     headerClasses: "id-header",
  //   },
  // ]
  const columnRefs = useRef([])

  // useEffect(() => {
  //   console.log(smscOwnerOptions)
  // }, [smscOwnerOptions])

  // useEffect(() => {
  //   columnRefs.current = columnRefs.current.slice(0, tableColumns.length);

  //   tableColumns.forEach((column, index) => {
  //     if (columnRefs.current[index]) {
  //       const columnWidth = columnRefs.current[index].offsetWidth;

  //       if (columnWidth < 100) { // Adjust as needed
  //         columnRefs.current[index].classList.add('rotated-header-text-rotated');
  //       } else {
  //         columnRefs.current[index].classList.remove('rotated-header-text-rotated');
  //       }
  //     }
  //   });
  // }, [filteredResults]);

  const cellStyle = cell => ({
    backgroundColor: cell !== 0 ? "#D4F4E8" : "inherit",
  })

  const defaultColumns = [
    {
      dataField: "appearances",
      text: "Appr.",
      sort: true,
      headerClasses: "country-header",
    },
    {
      dataField: "total_count",
      text: "Count",
      sort: true,
      headerClasses: "country-header",
    },
    {
      dataField: "sender_id",
      text: "Received Sender",
      // style: cellStyle,
      sort: true,
      headerClasses: "country-header",
    },
    {
      dataField: "smsc_owner",
      text: "SMSC Owner",
      // sort: true,
      headerClasses: "country-header2",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>SMSC Owner</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter" style={{ fontSize: "10px" }}>
                {filterElement}
              </div>
            </span>
          </div>
        )
      },
      editable: false,
      filter: selectFilter({
        options: { ...smscOwnerOptions.smscOwner, all: "All Options" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // Check if data is valid
          if (!data) return []

          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // Ensure smscOwnerOptions and smscOwnerOptions.smscOwner are valid
            if (!smscOwnerOptions || !smscOwnerOptions.smscOwner) return []

            if (filterVal === "Empty") {
              return data.filter(
                row => row.smsc_owner === "" || row.smsc_owner === null
              )
            } else {
              const value = smscOwnerOptions.smscOwner[filterVal]
              // Check if value is valid to avoid filtering with undefined
              if (value === undefined) return []
              return data.filter(row => row.smsc_owner === value)
            }
          }
        },
      }),
    },
    // {
    //   dataField: "smsc_owner",
    //   text: "SMSC Owner",
    //   sort: true,
    //   headerClasses: "smsc_owner_header",
    //   headerStyle: { width: '100px' }, // Set the width of the header
    //   style: { width: '100px' }, // Set the width of the body
    // },
  ]

  const columns2 = [
    ...defaultColumns,
    ...(tableColumns.length !== 0
      ? tableColumns.map(column => ({
          dataField: column,
          text: (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "36px",
              }}
            >
              <span className="rotated-header-text">{column}</span>
            </div>
          ),
          headerClasses:
            column === "Count" || column === "Network"
              ? "country-header"
              : "id-header",
          sort: column === "Count" || column === "Network",
          style: cellStyle,
          ...(column === "Count" || column === "Network"
            ? {
                text: (
                  <SortableHeader
                    title={column}
                    sortOrder={
                      column === "Count" ? sortOrderCountry : sortOrderOperator
                    }
                    onSortChange={
                      column === "Count"
                        ? handleSortChangeCountry
                        : handleSortChangeOperator
                    }
                  />
                ),
              }
            : {}),
        }))
      : []),
  ]

  const handleDateChange = debounce(value => {
    if (value) {
      if (value.length > 0) {
        setDateSetting(true)
        let start = new Date(value[0])
        let end = new Date(value[1])
        if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
          setStartDate("")
          setEndDate("")
          let Startdate = start.toISOString().split("T")[0]
          let Enddate = end.toISOString().split("T")[0]
          setStartDate(Startdate)
          setEndDate(Enddate)
        } else {
          setStartDate("")
          setEndDate("")
        }
        setDateSetting(false)
      } else {
        setStartDate("")
        setEndDate("")
      }
    } else {
      setStartDate("")
      setEndDate("")
    }
  }, 300)

  useEffect(() => {
    if (selectedCountry) {
      // console.log(selectedCountry);
      fetchOperators({ country_id: selectedCountry })
    } else {
      setOperator("")
      dispatch(setOperators([]))
    }
  }, [selectedCountry])

  useEffect(() => {
    if (countriesNew) {
      let data = countriesNew
      // console.log(data);
      dispatch(setCountries(data))
    }
  }, [countriesNew])

  useEffect(() => {
    if (operatorsNew && selectedCountry) {
      let data = operatorsNew
      // console.log(data);
      dispatch(setOperators(data))
    }
  }, [operatorsNew, selectedCountry])

  useEffect(() => {
    if (senderIDcount) {
      // console.log(senderIDcount);
      let tableData = get(senderIDcount, "data.sender_id_count", [])
      let tableColumnValues = get(senderIDcount, "data.columns", [])
      setTableColumns(tableColumnValues)
      setFilteredResults(tableData)
    }
  }, [senderIDcount])

  const handleSearchTest = debounce(value => {
    console.log(value)
    setSearchText(value)
    if (value) {
      let filteredData = resultsOverview.filter(item => {
        return item.operator_name.toLowerCase().includes(value.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(resultsOverview)
    }
  })
  const CustomClearIndicator = props => {
    return (
      <div
        onClick={props.clearValue}
        style={{ cursor: "pointer", padding: "0 5px" }}
      >
        <svg
          width="14px"
          height="14px"
          style={{ marginRight: 10 }}
          color="black"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class="rs-icon"
          aria-label="close"
          data-category="application"
        >
          <style>
            {`
              .rs-icon:hover {
                fill: red;
                border-width: '5px';
              }
            `}
          </style>
          <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
        </svg>
      </div>
    )
  }

  const DropdownIndicator = props => {
    return (
      // props.selectProps.value ? null : (
      <components.DropdownIndicator {...props}>
        <svg
          height="20"
          fill="#808080"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          class=""
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </components.DropdownIndicator>
      // )
    )
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Sender ID Count</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Analytics" breadcrumbItem="Sender ID Count" />
        <Card>
          <CardBody className="custom-cardbody">
            <Row
              className="pb-4"
              style={{
                zIndex: "2",
                position: "relative",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Col md="4" className="d-flex">
                <div className="d-flex" style={{}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      // alignItems: 'center',
                      width: "100%",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <label
                        className="search-label"
                        style={{ fontWeight: "600", fontSize: "14px" }}
                      >
                        Received Sender Type :&nbsp;
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <label
                        className="search-label me-2"
                        style={{ fontWeight: "400", fontSize: "14px" }}
                      >
                        Shortcode
                      </label>
                    </div>
                    <div className="shortCode_toggle d-flex align-items-center">
                      <Toggle
                        size="ms"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        color="red"
                        defaultChecked={shortCode}
                        onChange={e => {
                          setShortCode(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  value={sortedCountries.filter(
                    option => option.value === selectedCountry
                  )}
                  name="country"
                  // options={[
                  //   { value: "10", label: "Last 10 Tests" },
                  //   { value: "20", label: "Last 20 Tests" },
                  //   { value: "50", label: "Last 50 Tests" },
                  // ]}
                  options={[
                    { value: "", label: "No Country Selected" },
                    ...sortedCountries,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setOperator("")
                        dispatch(setOperators([]))
                        setCountry(e.value)
                      }
                      if (e.value === "") {
                        setCountry("")
                      }
                    } else {
                      setCountry("")
                    }
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedCountry
                      ? DropdownIndicator
                      : null,
                  }}
                  placeholder="Country"
                />
              </Col>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  name="operator"
                  value={operator.filter(
                    option => option.id === selectedOperator
                  )}
                  isDisabled={selectedCountry === "" ? true : false}
                  // options={[
                  //   { value: "1", label: "Template 1" },
                  //   { value: "2", label: "Template 2" },
                  //   { value: "3", label: "Template 3" },
                  // ]}
                  options={[
                    { value: "", label: "No Operator Selected" },
                    ...operator,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.id) {
                        setOperator(e.id)
                      }
                      if (e.value === "") {
                        setOperator("")
                      }
                    } else {
                      setOperator("")
                    }
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedOperator
                      ? DropdownIndicator
                      : null,
                  }}
                  placeholder="Operator"
                />
              </Col>
              <Col md="2">
                <Select
                  className="basic-single fixed-height"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  name="xTest"
                  placeholder="Last X Test"
                  options={[
                    { value: "5", label: "Last 5 Tests" },
                    { value: "10", label: "Last 10 Tests" },
                    { value: "20", label: "Last 20 Tests" },
                    // { value: "50", label: "Last 50 Tests" },
                    // { value: "100", label: "Last 100 Tests" },
                    // { value: "1000", label: "Last 1000 Tests" },
                  ]}
                  // options={[
                  //   { value: '', label: 'No X Test Selected' },
                  //   ...xtest
                  // ]}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setXTest(e.value)
                      }
                      if (e.value === "") {
                        setXTest("")
                      }
                    } else {
                      setXTest("")
                    }
                  }}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedXTest
                      ? DropdownIndicator
                      : null,
                  }}
                />
              </Col>
              <Col md="2" className="">
                <div style={{ width: "100%" }}>
                  <DateRangePicker
                    ranges={predefinedBottomRanges}
                    className="basic-single "
                    placeholder="Select Date Range"
                    style={{ height: "38px", width: "100%" }}
                    placement="autoVerticalEnd"
                    onChange={handleDateChange}
                    defaultValue={[sixMonthsAgo, new Date()]}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive scrollable2 table_shadow_div reportdata_table3">
                  <BootstrapTable
                    keyField="id"
                    data={filteredResults}
                    columns={columns2}
                    bootstrap4={true}
                    filter={filterFactory()}
                    noDataIndication={() => {
                      if (senderIDfetching === true) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <LoadingSpinner />
                          </div>
                        )
                      } else {
                        return <div>No Data Found</div>
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
