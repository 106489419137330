import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css";
import store from "../../../store/index";
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"


const FilteredReports = (props) => {
  async function deleteProj (id) {
    Swal.fire({
      title: 'Do you want really want to Delete the Report',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.log(id);
        dispatch(deleteReport(id))
        let Deletpromise = new Promise(async(resolve,reject)=>{
          store.subscribe(()=>{
            const deleteSuccess = store.getState();
            const status = deleteSuccess.Reports.deleteSuccess
            if(status == true){
              // console.log(status);
              resolve("Done")
            }
          })
        })
        await Deletpromise.then(async(msg)=>{
          // Toast.fire({
          //   icon: 'success',
          //   title: 'Delete Success!'
          // })
          console.log("Delete Success");
          Swal.fire('Delete Success!', '', 'success')
          // Toast.fire({
          //   icon: 'success',
          //   title: 'File Deleted'
          // })
          dispatch(setdeleteReportSuccess(false))
          setTimeout(() => {
          searchReport();
          // dispatch(getProjectData())  
          }, 1500);
        })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  const searchedBatches = props.searchedBatches;
  const selectedRows=useSelector(state=>state.Reports.selectedReportToGenerate);
  const searchedBatches_loading = useSelector((state) => state.SourceUpload.batchGetSuccess);
  const columns = [
    {
      dataField: "batch_name",
      text: "Batch Name",
      sort: true,
    },

    {
      dataField: "uploadedDate",
      text: "Date Uploaded",
      sort: true,
    },
    
    {
      dataField: "customer",
      text: "Customer",
      sort: true,
    },
    {
      dataField: "operator",
      text: "Operator",
      sort: true,
    },
    {
      dataField: "username",
      text: "Uploaded By",
      sort: true,
    }
    // {
    //   dataField: "",
    //   text:"Delete",
    //   formatter: function(){
    //     return(
    //       <Button 
    //       color="danger" 
    //       size="sm" 
    //       className="btn-rounded waves-effect waves-light"
    //       onClick={() => {
    //         deleteProj()
    //       }}
    //       >
    //        <i className="mdi mdi-delete"></i>
    //       </Button>
    //     )
    //   }
    // }
    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   sort: true,
    // },
  ]
  // Table Data
  const productData = [
    {
      id: 1,
      fileName: "Giovanni",

    },
    {
      id: 2,
      fileName: "Giovanni",

    },



  ]
  const pageOptions = {
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '25', value: 25 },
    ],
    totalSize: searchedBatches.length, // replace later with size(customers),
    custom: true,
    // onSizePerPageChange:(size)=>{
    //   props.loadRows(size)
    // }
  }
  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]
  // Select All Button operation
  const selectRow = {
    mode: 'radio',
    selected : selectedRows,
    onSelect: (row, isSelect, rowIndex, e) => {
      selectedRows[0]=row;
    }
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="pb-4">
        <div >
          <Row>
            <Col className="col-12">


              {/* <Table className="align-middle mb-0">
                <thead>
                  <tr>
                    <th>Source Data</th>
                    <th>Date Uploaded</th>
                    <th>Actions</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.searchedBatches.map((item) => {
                    return (
                      <tr>
                        <td>{item.id}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table> */}
               <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={searchedBatches}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={searchedBatches}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                           
                            <Row >
                                   
                                   <Col md="3" sm="12">
                                       <div className="search-box me-2 my-2 d-inline-block w-sm-100">
                                           <div className="position-relative">
                                               <SearchBar
                                                   {...toolkitProps.searchProps}
                                                   style={{ height: "35px" }}
                                               />
                                               <i className="bx bx-search-alt search-icon sm-left-30" />
                                           </div>
                                       </div>
                                   </Col>
                               </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    noDataIndication={()=>{
                                      if(searchedBatches_loading===false){
                                        return <div style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                          <LoadingSpinner/>
                                        </div>
                                      }
                                      else{
                                        return <div style={{
                                          textAlign: "center",
                                        }}>No Data Found</div>
                                      }
                                    }}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone 
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>


            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FilteredReports
