import React from "react"

import ReactApexChart from "react-apexcharts"

const chartapex = props => {
  const series = [
    { name: "Official MTR", data: [0.055, 0.055, 0.065, 0.065] },
    {
      name: "Avg. Price of official Routes",
      data: [0.04, 0.0383, 0.0433, 0.0424],
    },
    {
      name: "Avg. Price of Delevering Routes",
      data: [0.04, 0.0333, 0.0433, 0.0424],
    },
  ]

  let maxYValue = 0.0

  if (props.chartData) {
    for (var Obj of props.chartData) {
      if (Obj.name == "Official MTR") {
        for (var data1 of Obj.data) {
          if (data1 > maxYValue && data1 != "NaN") {
            maxYValue = parseFloat(data1).toFixed(4)
          }
        }
      }
      if (Obj.name == "Avg. price of official routes") {
        for (var data2 of Obj.data) {
          if (data2 > maxYValue && data2 != "NaN") {
            maxYValue = parseFloat(data2).toFixed(4)
          }
        }
      }
      if (Obj.name == "Avg. price of delivering routes") {
        for (var data3 of Obj.data) {
          if (data3 > maxYValue && data3 != "NaN") {
            // maxYValue = data3;
            maxYValue = parseFloat(data3).toFixed(4)
          }
        }
      }
      // console.log("Max value out of all Pricing data :" + maxYValue);
    }
  }
  //const kpiData = props.chartData?props.chartData:[];
  //var dataCheck = kpiData.length==0?series:kpiData.pricing_chart_data;  /* deliverd, not deliverd, kpi prop have tale length ? */
  //var chartData = dataCheck?.length==0?series:dataCheck; /* kpireports have tale length ? */

  const options = {
    chart: {
      zoom: { enabled: false }, // Disabling zoom
      toolbar: { show: false }, // Hiding toolbar
    },
    colors: ["#36404f", "#1DAD61", "#1DAD61", "#FB4735"], // Setting line colors

    dataLabels: { enabled: false }, // Hiding data labels on points

    stroke: {
      width: [3, 3, 3, 3],
      curve: "smooth",
      dashArray: [0, 0, 4, 4],
    },

    title: { text: "Pricing KPIs", align: "left" },

    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },

    xaxis: {
      categories: props.dates,
      title: { text: "Month" },
    },

    yaxis: {
      title: { text: "Price" },
      labels: {
        formatter: function (value) {
          return value ? value.toFixed(4) : 0
        },
      },
      min: 0.0,
      max: maxYValue * 1.1, // Adds 10% buffer
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 5,
      offsetX: -15,
      show: true,
      markers: {
        customHTML: [
          function () {
            return '<div style="width: 15px;  height: 3px; background-color: #36404f; margin-right:2px;"></div>'
          }, // Solid green line
          function () {
            return '<div style="width: 55px; height: 3px; background-color: #1DAD61; margin-right:2px;"></div>'
          }, // Solid red line
          function () {
            return '<div style="width: 55px; height: 3px; border-top: 2px dashed #1DAD61; margin-right:2px;"></div>'
          }, // Dashed green line
        ],
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={props.chartData ? props.chartData : series}
      type="line"
      height="380"
      // className="apexcharts-canvas apexchartscq310u9c apexcharts-theme-light"
    />
  )
}

export default chartapex
