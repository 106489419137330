import React, { Suspense, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import excelimage from "../../assets/images/excel.png"
import deleteimage from "../../assets/images/delete.png"

import Select from "react-select"
import LoadingSpinnerUpload from "./LoadingSpinner_upload"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { get } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  getPartners,
  getTemplates,
  uploadBatchSuccess,
} from "store/old/source-upload/actions"
import moment from "moment"
import toastr from "toastr"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import * as XLSX from "xlsx"
import {
  useGetUploadedFilesQuery,
  useUploadFilesMutation,
} from "store/sourceUpload/sourceUpload.api"
import {
  setRecentlyUploads,
  setBatchData,
  updatePartner,
  clearPairs,
  removePairs,
} from "store/sourceUpload/sourceUpload.slice"
import { useGetCustomersQuery } from "store/customer/customer.api"
import { setCustomers } from "store/customer/customer.slice"
import { useGetOperatorsQuery } from "store/operator/operator.api"
import { setOperators } from "store/operator/operator.slice"
import { useGetPartnersQuery } from "store/partner/partner.api"
import { setPartners } from "store/partner/partner.slice"
import { useGetTemplatesQuery } from "store/template/template.api"
import { setTemplates } from "store/template/template.slice"

import SMSCInspector from "components/SourceUpload/inspectorContent"

const SourceUpload = () => {
  const dispatch = useDispatch()
  const [modal_inspector, setModal_inspector] = useState(false)
  const [selectedTemplate, setTemplate] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedCustomer, setCustomer] = useState(null)
  const [selectedPartners, setSelectedPartners] = useState({})
  const [selectedDate, setTestDate] = useState("")
  const [modal_pipe_upload, setmodal_pipe_upload] = useState(false)
  const [selectedServerFiles, setselectedServerFiles] = useState([])
  const [selectedPartnerFiles, setselectedPartnerFiles] = useState([])
  const [pairNumber, setPairNumber] = useState(0)
  const [fileTestDate, setFileTestDate] = useState("")
  // const pairsArray = useSelector((state)=>state.sourceUploadNew.pairsArray);
  const { recentUploads } = useSelector(state => state.sourceUploadNew)
  const {
    data: recentUploadsNew,
    error,
    isFetching,
    refetch: refetchRecentUploads,
  } = useGetUploadedFilesQuery()
  const { pairsArray } = useSelector(state => state.sourceUploadNew)
  const [
    uploadFiles,
    { isLoading, error: uploadError, isSuccess: uploadSuccess },
  ] = useUploadFilesMutation()
  const {
    data: customersNew,
    error: customersError,
    isFetching: customersFetching,
  } = useGetCustomersQuery()
  const { customers: customer } = useSelector(state => state.customerNew)
  const {
    data: operatorsNew,
    error: operatorsError,
    isFetching: operatorsFetching,
  } = useGetOperatorsQuery(
    {
      customer_body: selectedCustomer ? { customer_id: selectedCustomer } : "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const { operators: operator } = useSelector(state => state.operatorNew)
  const {
    data: partnersNew,
    error: partnersError,
    isFetching: partnersFetching,
  } = useGetPartnersQuery()
  const { partners: partners } = useSelector(state => state.partnerNew)
  const {
    data: templatesNew,
    error: templatesError,
    isFetching: templatesFetching,
  } = useGetTemplatesQuery()
  const { templates: template } = useSelector(state => state.templateNew)

  function partnerOnChangeHandler(value, id) {
    // for (let i = 0; i < pairsArray.length; i++) {
    //   if (pairsArray[i].pairId == id) {
    //     pairsArray[i].partner = value.value
    //   }
    // }
    dispatch(updatePartner({ id, value: value.value }))
    setSelectedPartners(prevPartners => {
      if (prevPartners) {
        const newPartners = prevPartners
        newPartners[id] = value
        return newPartners
      } else {
        let temp = {
          [id]: value,
        }
        return temp
      }
    })
  }
  const removeRow = keyToRemove => {
    setSelectedPartners(prevPartners => {
      const newPartners = { ...prevPartners } // Make a copy of the state
      delete newPartners[keyToRemove] // Delete the partner from the copy
      return newPartners // Return the updated state
    })
  }

  useEffect(() => {
    dispatch(setRecentlyUploads(recentUploadsNew))
  }, [recentUploadsNew])

  useEffect(() => {
    if (customersNew) {
      let data = get(customersNew, "data", [])
      dispatch(
        setCustomers(
          data.map(obj => {
            return { id: obj.id, label: obj.name, value: obj.name }
          })
        )
      )
    }
  }, [customersNew])

  useEffect(() => {
    if (operatorsNew) {
      let data = get(operatorsNew, "message", [])
      // console.log(data);
      const operator = data.map(obj => {
        return {
          id: obj.id,
          label: obj.country + " " + obj.operator_name,
          value: obj.country + " " + obj.operator_name,
        }
      })
      operator.sort((a, b) => {
        // console.log(a,b);
        const countryA = a.label.split(" ")[0]
        const countryB = b.label.split(" ")[0]

        // Compare country names
        if (countryA < countryB) {
          return -1
        } else if (countryA > countryB) {
          return 1
        } else {
          return 0
        }
      })
      // dispatch(setOperators(data.map(obj=>{
      //   return {id:obj.id,label:obj.name,value:obj.name}
      // })))
      dispatch(setOperators(operator))
    }
  }, [operatorsNew])

  useEffect(() => {
    if (partnersNew) {
      let data = get(partnersNew, "partners", [])
      dispatch(
        setPartners(
          data.map(obj => {
            return { id: obj.id, label: obj.partner_name, value: obj.id }
          })
        )
      )
    }
  }, [partnersNew])

  useEffect(() => {
    if (templatesNew) {
      let data = get(templatesNew, "templates", [])
      dispatch(setTemplates(data))
    }
  }, [templatesNew])

  useEffect(async () => {
    if (pairsArray.length > 0) {
      await calcTestDate(pairsArray)
      await getTemplateFromFile(pairsArray)
    }
  }, [pairsArray?.length])

  // const customertest = useSelector((state)=>state.customer.customers);
  // const customer = customertest.map(obj=>{
  //   return {id:obj.id,label:obj.name,value:obj.name}
  // })
  // const operatortest = useSelector((state)=>state.operator.operators);
  // const operator = operatortest.map(obj=>{
  //   return {id:obj.id,
  //     label:obj.country+" "+obj.operator_name,
  //     value:obj.country+" "+obj.operator_name}
  // })

  // const template = useSelector((state)=>state.SourceUpload.templates);
  // const partnertest = useSelector((state)=>state.SourceUpload.partners);
  // const partners = partnertest.map(obj=>{
  //   return {id:obj.id,label:obj.partner_name,value:obj.id}
  // })
  const handleAcceptedServerFiles = files => {
    setselectedServerFiles([])
    // console.log(files);

    const reader = new FileReader()
    reader.onload = async () => {
      const fileData = reader.result
      const workbook = XLSX.read(fileData, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      // Check the order of the header columns
      const expectedHeaders = ["Message ID", "Date", "Username"]
      const newFormatHeaders = ["ID", "Test ID text", "Date (UTC)"]
      const actualHeaders = data[0].slice(0, 3)
      // console.log(actualHeaders);
      const isHeaderOrderCorrect = expectedHeaders.every(
        (header, index) => actualHeaders[index] === header
      )
      const isNewFormat = newFormatHeaders.every(
        (header, index) => actualHeaders[index] === header
      )
      if (!isHeaderOrderCorrect && !isNewFormat) {
        // Handle error here
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Incorrect File Uploaded. Please upload a valid test file",
        })
        // console.log('Incorrect File Uploaded. Please upload a valid test file');
        return
      }
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      // console.log(files);

      setselectedServerFiles(files)
    }
    reader.readAsBinaryString(files[0])
  }

  function handleAcceptedPartnerFiles(files) {
    setselectedPartnerFiles([])
    const reader = new FileReader()
    reader.onload = () => {
      const fileData = reader.result
      const workbook = XLSX.read(fileData, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

      // Check the order of the header columns
      const column30 = data.map(row => row[29])
      const column31 = data.map(row => row[30])
      const isCurrencyAndBuyPriceInColumns30And31 =
        column31.includes("Currency") && column30.includes("Buy price")
      // console.log(isCurrencyAndBuyPriceInColumns30And31);
      // Check if columns 8 and 9 include "Currency" and "Buy Price"
      const column8 = data.map(row => row[6])
      // console.log(column8);
      const column9 = data.map(row => row[7])
      // console.log(column9);
      const isCurrencyAndBuyPriceInColumns8And9 =
        column8.includes("Vendor currency") && column9.includes("Vendor rate")
      // console.log(isCurrencyAndBuyPriceInColumns8And9);
      // console.log(actualHeaders);

      if (
        !isCurrencyAndBuyPriceInColumns30And31 &&
        !isCurrencyAndBuyPriceInColumns8And9
      ) {
        // Handle error here
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Incorrect File Uploaded. Please upload a valid Partner File",
        })
        // console.log('Incorrect File Uploaded. Please upload a valid test file');
        return
      }
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setselectedPartnerFiles(files)
    }
    reader.readAsBinaryString(files[0])
  }

  async function AddToBatchHandler() {
    if (selectedPartnerFiles[0] != null && selectedServerFiles[0] != null) {
      setPairNumber(pairNumber + 1)
      const pairData = {
        pairId: pairNumber + 1,
        files: {
          test: selectedServerFiles[0],
          partner: selectedPartnerFiles[0],
        },
        partner: "",
      }
      // setPairArray(pairsArray => [...pairsArray, pairData])
      dispatch(setBatchData(pairData))
      setselectedPartnerFiles([])
      setselectedServerFiles([])
      await calcTestDate(pairsArray)
    } else {
      alert("Please add both Partner and Test Files")
    }
  }

  const calcTestDate = async pairs => {
    let testDate = new Set()
    for (const element of pairs) {
      // console.log(pairs,element);
      const files = element.files.test
      // console.log(files);
      const reader = new FileReader()
      const fileData = await new Promise(resolve => {
        reader.onload = () => resolve(reader.result)
        reader.readAsBinaryString(files)
      })
      const workbook = XLSX.read(fileData, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      // Get the index of the 'Date' column
      // console.log(data);
      const dateIndex = data[0].indexOf("Date")
      const newDateIndex = data[0].indexOf("Date (UTC)")

      // Extract the values of the 'Date' column
      let dateValues = []
      if (newDateIndex != -1) {
        dateValues = data.slice(1).map(row => {
          const dateStr = row[newDateIndex]
          // console.log(dateStr);
          if(dateStr){
          const [day, month, year, hour, minute, second] =
            dateStr.split(/[- :]/)
          const date = new Date(
            Date.UTC(year, month - 1, day, hour, minute, second)
          )

          const yearUTC = date.getUTCFullYear()
          const monthUTC = String(date.getUTCMonth() + 1).padStart(2, "0")
          const dayUTC = String(date.getUTCDate()).padStart(2, "0")
          const hourUTC = String(date.getUTCHours()).padStart(2, "0")
          const minuteUTC = String(date.getUTCMinutes()).padStart(2, "0")
          const secondUTC = String(date.getUTCSeconds()).padStart(2, "0")

          return `${yearUTC}-${monthUTC}-${dayUTC}T${hourUTC}:${minuteUTC}:${secondUTC}+00:00`
          }
        })

        dateValues.forEach(value => {
          if (value) {
            // console.log(value.split("T")[0]);
            let Tvalue = value.split("T")[0]
            testDate.add(Tvalue)
          }
        })
      } else if (dateIndex != -1) {
        dateValues = data.slice(1).map(row => row[dateIndex])
        //splice the string to get yyyy-mm-dd
        dateValues.forEach(value => {
          if (value) {
            // console.log(value.split("T")[0]);
            let Tvalue = value.split("T")[0]
            testDate.add(Tvalue)
          }
        })
      }
      // Add the values to the set
      // console.log(dateValues);
      // dateValues.forEach((value) => testDate.add(value));
    }
    // console.log(testDate,pairs);
    if (testDate.size == 1) {
      setFileTestDate(testDate.entries().next().value[0])
    }
    if (testDate.size > 1) {
      // console.log("Multiple Dates");
      // Convert the set to an array of arrays
      const testDateArray = Array.from(testDate, value => [value, value])
      // Display a Swal alert for the user to choose the correct date
      const { value: date } = await Swal.fire({
        title: "Multiple Test Dates Detected. Please Select Test Date",
        input: "select",
        inputOptions: Object.fromEntries(testDateArray),
        inputPlaceholder: "Select a date",
        showCancelButton: false,
        inputValidator: value => {
          return new Promise(resolve => {
            if (value) {
              resolve()
            } else {
              resolve("Please select a date")
            }
          })
        },
      })
      if (date) {
        setFileTestDate(date)
        // console.log(date,fileTestDate);
      }
    }
  }

  const getTemplateFromFile = async pairs => {
    let template_set = new Set()
    // console.log(pairs);
    for (const element of pairs) {
      // console.log(pairs,element);
      const files = element.files.test
      // console.log(files);
      const reader = new FileReader()
      const fileData = await new Promise(resolve => {
        reader.onload = () => resolve(reader.result)
        reader.readAsBinaryString(files)
      })
      const workbook = XLSX.read(fileData, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      // Get the index of the 'Date' column
      // console.log(data);
      // const dateIndex = data[0].indexOf('Sender	');
      // const newDateIndex = data[0].indexOf('Sender ID Sent');
      const templateIndex = data[0].indexOf("Sender")
      const newTemplateIndex = data[0].indexOf("Sender ID Sent")

      // Extract the values of the 'Date' column
      let templateValues = []
      if (newTemplateIndex != -1) {
        templateValues = data.slice(1).map(row => {
          const template = row[newTemplateIndex]
          return template
        })

        templateValues.forEach(value => {
          if (value) {
            // console.log(value.split("T")[0]);
            template_set.add(value)
          }
        })
      } else if (templateIndex != -1) {
        templateValues = data.slice(1).map(row => row[templateIndex])
        //splice the string to get yyyy-mm-dd
        templateValues.forEach(value => {
          if (value) {
            // console.log(value.split("T")[0]);
            template_set.add(value)
          }
        })
      }
    }
    if (template_set.size == 1) {
      // setFileTestDate(template.entries().next().value[0])
      let templateValue = template_set.entries().next().value[0]
      let templateId = template?.filter(obj => obj.label === templateValue)[0]
        ?.id
      if (!templateId) {
        toastr.error("Template not found")
      } else {
        setTemplate(templateId)
      }
    } else if (template_set.size > 1) {
      // console.log("Multiple Dates");
      // Convert the set to an array of arrays
      const templateDataArray = Array.from(template_set, value => [
        value,
        value,
      ])
      // Display a Swal alert for the user to choose the correct date
      const { value: selected_template } = await Swal.fire({
        title: "Multiple Template Values Detected. Please Select Template",
        input: "select",
        inputOptions: Object.fromEntries(templateDataArray),
        inputPlaceholder: "Select a Template",
        showCancelButton: false,
        inputValidator: value => {
          return new Promise(resolve => {
            if (value) {
              resolve()
            } else {
              resolve("Please select a template")
            }
          })
        },
      })
      if (selected_template) {
        // setTemplate(selected_template)
        let templateId = template?.filter(
          obj => obj.label === selected_template
        )[0]?.id
        if (!templateId) {
          toastr.error("Template not found")
        } else {
          setTemplate(templateId)
        }
      }
    }
  }

  function epoch(date) {
    return Date.parse(date)
  }

  async function UploadButtonHandler() {
    // console.log(selectedCustomer,selectedOperator,selectedTemplate);
    
    if (
      selectedCustomer != "" &&
      selectedOperator != "" &&
      selectedTemplate != "" &&
      fileTestDate != "" &&
      selectedCustomer != null
    ) {
      const dateToday = new Date()
      const timestamp = epoch(dateToday)
      setmodal_pipe_upload(true)

      var formData = new FormData()
      var key = []
      for (let i = 0; i < pairsArray.length; i++) {
        var element = pairsArray[i]
        if (element.partner == "") {
          // toas("File"+element.pairId+" has no partner selected")
          toastr.error(
            "Pair" +
              element.pairId +
              " wasn't uploaded because no partner was selected"
          )
        } else {
          key.push(element.pairId)
          formData.append(
            "pair_" + element.pairId + "_fpartner",
            element.files.partner
          )
          formData.append(
            "pair_" + element.pairId + "_ftest",
            element.files.test
          )
          formData.append(
            "pair_" + element.pairId + "_partner",
            element.partner
          )
        }
      }

      formData.append("customer", selectedCustomer)
      formData.append("operator", selectedOperator)
      formData.append("test_date", fileTestDate)
      formData.append("template", selectedTemplate)
      formData.append("timeStamp", timestamp)
      formData.append("keys", JSON.stringify(key))

      if (key.length > 0) {
        await uploadFiles({ formData })
          .unwrap()
          .then(data => {
            setmodal_pipe_upload(false)
            Swal.fire("Upload Success!", "", "success")
            dispatch(uploadBatchSuccess(false))
            refetchRecentUploads()
            dispatch(clearPairs([]))
            setCustomer(null)
            setOperator("")
            setTemplate("")
          })
          .catch(err => {
            setmodal_pipe_upload(false)
            if (err.data) {
              console.log(err)
              Swal.fire(`Upload Failed! ${err.data.message}`, "", "error")
            } else {
              const { name, message } = err
              console.log(err, name, message)
              Swal.fire(
                `Upload Failed!\nError type: ${name}\n Error :${message}\n If Error=Aborted, the request timed out.`,
                "",
                "error"
              )
            }
            dispatch(uploadBatchSuccess(false))
          })
      } else {
        setmodal_pipe_upload(false)
      }
    } else {
      // alert("Please fill all the fields")
      Swal.fire("Please fill all the fields", "", "error")
      setmodal_pipe_upload(false)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    var now = new Date()
    var dateString = moment(now).format("YYYY-MM-DD")
    setTestDate(dateString)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Upload Files</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Upload Files" breadcrumbItem="Upload Files" />
          <Row>
            <Col className=" me-2">
              <div className="pe-4">
                <div className="pb-1">
                  <h5>GTC Test Server </h5>
                </div>
              </div>
              <Card className="card_new">
                <CardBody>
                  <Form>
                    <Dropzone
                      className="dropzon1"
                      maxFiles={1}
                      multiple={false}
                      accept={[".xlsx"]}
                      validator={file => {
                        var x = file.path?.split(".")
                        let extension = x[x.length - 1]
                        if (
                          extension === "xlsx" ||
                          // extension === "xls" ||
                          extension === "csv"
                        ) {
                          return null
                        } else {
                          // alert("Please Select SpreadSheet File(XLSX or CSV) to Continue")
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Please Select XLSX File to Continue",
                          })
                          return {
                            error: "Please Select SpreadSheet File to Continue",
                          }
                        }
                      }}
                      onDrop={acceptedFiles => {
                        handleAcceptedServerFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone1">
                          <div
                            className="dz-message needsclick"
                            style={{ marginTop: "5px" }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-1 box3">
                              <i class="upload_icon fas fa-cloud-upload-alt"></i>
                            </div>
                            <h4>
                              Drop files here or{" "}
                              <span className="upload_text">
                                click to upload{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div
                      className="dropzone-previews d-flex align-items-center"
                      id="file-previews"
                    >
                      <div className="col-md-6">
                        {selectedServerFiles.length === 0 ? (
                          <Row className="">
                            <div className="col-md-12">
                              <div>
                                <h6 className="file_text">
                                  <i
                                    className=" bx bx-paperclip "
                                    style={{ rotate: "140deg" }}
                                  />
                                  &nbsp; No file added
                                </h6>
                              </div>
                              <div className="support_text">
                                {" "}
                                Supports: XLSX
                              </div>
                              <div className="support_text">
                                {" "}
                                Maximum file size of 20mb
                              </div>
                            </div>
                          </Row>
                        ) : (
                          selectedServerFiles.map((f, i) => {
                            let displayName = f.name
                            let MAX_NAME_LENGTH = 45
                            if (displayName.length > MAX_NAME_LENGTH) {
                              const ellipsis = "..."
                              const sliceLength =
                                (MAX_NAME_LENGTH - ellipsis.length) / 2
                              displayName = `${displayName.slice(
                                0,
                                sliceLength
                              )}${ellipsis}${displayName.slice(-sliceLength)}`
                            }
                            return (
                              <Card
                                style={{ boxShadow: "none" }}
                                className="ms-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="">
                                  <Row className="align-items-center">
                                    <Col className="col-auto ">
                                      <img
                                        data-dz-thumbnail=""
                                        height="68"
                                        className="rounded bg-light"
                                        // alt={f.name}
                                        src={excelimage}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold truncate-text"
                                      >
                                        {displayName}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        )}
                      </div>
                      <div className="col-md-6 dropzone-row-2">
                        <button
                          type="button"
                          className="btn me-4 ms-auto p-2 advanced-upload"
                          onClick={() => {
                            setModal_inspector(!modal_inspector)
                          }}
                        >
                          Advanced Upload
                        </button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col className="ms-2 me-3">
              <div className="pe-4">
                <div className="pb-1">
                  <h5>GTC Partner </h5>
                </div>
              </div>
              <Card className="card_new">
                <CardBody>
                  <Form>
                    <Dropzone
                      className="dropzone1"
                      maxFiles={1}
                      accept={[".xlsx"]}
                      multiple={false}
                      validator={file => {
                        var x = file.path?.split(".")
                        let extension = x[x.length - 1]
                        if (
                          extension === "xlsx" ||
                          // extension === "xls" ||
                          extension === "csv"
                        ) {
                          return null
                        } else {
                          // alert("Please Select SpreadSheet File (XLSX or CSV) to Continue")
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Please Select CSV File to Continue",
                          })
                          return {
                            error: "Please Select SpreadSheet File to Continue",
                          }
                        }
                      }}
                      onDrop={acceptedFiles => {
                        handleAcceptedPartnerFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone1">
                          <div
                            className="dz-message needsclick"
                            style={{ marginTop: "5px" }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-1 box3">
                              <i class="fas fa-cloud-upload-alt upload_icon "></i>
                            </div>
                            <h4>
                              Drop files here or{" "}
                              <span className="upload_text">
                                click to upload{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews" id="file-previews">
                      <div className="col-md-6">
                        {selectedPartnerFiles.length === 0 ? (
                          <Row>
                            <div className="">
                              <h6 className="file_text">
                                <i
                                  className=" bx bx-paperclip "
                                  style={{ rotate: "140deg" }}
                                />
                                &nbsp; No file added
                              </h6>
                            </div>
                            <div className="support_text"> Supports: XLSX</div>
                            <div className="support_text">
                              {" "}
                              Maximum file size of 20mb
                            </div>
                          </Row>
                        ) : (
                          selectedPartnerFiles.map((f, i) => {
                            let displayName = f.name
                            let MAX_NAME_LENGTH = 45
                            if (displayName.length > MAX_NAME_LENGTH) {
                              const ellipsis = "..."
                              const sliceLength =
                                (MAX_NAME_LENGTH - ellipsis.length) / 2
                              displayName = `${displayName.slice(
                                0,
                                sliceLength
                              )}${ellipsis}${displayName.slice(-sliceLength)}`
                            }
                            return (
                              <Card
                                className="ms-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="68"
                                        className="rounded bg-light"
                                        // alt={f.name}
                                        src={excelimage}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold truncate-text"
                                      >
                                        {displayName}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        )}
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="d-flex align-items-center" style={{paddingRight:"28px"}}>
              {selectedPartnerFiles.length != 1 &&
              selectedServerFiles.length != 1 ? (
                <button
                  className="btn ms-auto p-2 add-to-batch"
                  disabled
                >
                  Add to Batch
                </button>
              ) : (
                <>
                  <button
                    className="btn  ms-auto p-2"
                    onClick={AddToBatchHandler}
                    style={{
                      width: "125px",
                      height: "42px",
                      fontSize: "14px",
                      color: "#811113",
                      border: "2px solid #811113",
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                  >
                    Add to Batch
                  </button>
                </>
              )}
            </div>
          </Row>
          <Row>
            <h5 style={{ paddingTop: "10px", fontWeight: "bold" }}>
              Pending Batch
            </h5>
          </Row>
          {pairsArray.length == 0 ? (
            <></>
          ) : (
            <>
              <Row style={{ marginTop: "20px" }} className="mb-3">
                <Col
                  sm="4"
                  md="2"
                  lg="2"
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <div>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="Customer"
                      placeholder="Select Customer"
                      // options={customer}
                      options={[
                        { value: "", label: "No Customer Selected" },
                        ...customer,
                      ]}
                      onChange={e => {
                        if (e.id) {
                          setCustomer(e.id)
                          setOperator("")
                          // dispatch(getNewOperators({
                          //   customer_id: e.id
                          // }))
                        }
                        if (e.value === "") {
                          // dispatch(getNewOperators())
                          setCustomer(null)
                          setOperator("")
                        }
                      }}
                    />
                  </div>
                </Col>
                {/* <Col sm="6" md="6" lg="6" style={{
              paddingTop: "5px"
            }}>
              <input
                className="form-control"
                type="date"
                id="test-date-input"
                style={{ height: "38px" }}
                value={selectedDate}
                onChange={e => {
                  setTestDate(e.target.value)
                  // setTestDate(1)
                }}
              />
            </Col> */}
                <Col
                  sm="4"
                  md="2"
                  lg="2"
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <div>
                    <Select
                      placeholder="Select Operator"
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      value={operator.filter(
                        option => option.id === selectedOperator
                      )}
                      name="Operator"
                      // options={operator}
                      options={[
                        { value: "", label: "No Operator Selected" },
                        ...operator,
                      ]}
                      onChange={e => {
                        if (e.id) {
                          setOperator(e.id)
                        }
                        if (e.value === "") {
                          setOperator("")
                        }
                      }}
                    />
                  </div>
                </Col>

                <Col
                  sm="4"
                  md="2"
                  lg="2"
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <div>
                    <Select
                      placeholder="Select Template"
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      value={template.filter(
                        option => option.id === selectedTemplate
                      )}
                      name="template"
                      options={template}
                      onChange={e => {
                        setTemplate(e.id)
                      }}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="d-flex " style={{ paddingTop: "12px" }}>
                    <Button
                      color="danger"
                      className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                      onClick={() => {
                        UploadButtonHandler()
                      }}
                    >
                      Upload Batch
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <div style={{ borderRadius: "10px", minHeight: "136px" }}>
            {" "}
            <div style={{ paddingTop: "40px" }} className="container-fluid">
              {pairsArray?.map((file, key) => {
                let displayName_test = file?.files?.test.path
                let displayName_partner = file?.files?.partner.path
                let MAX_NAME_LENGTH = 45
                if (displayName_test.length > MAX_NAME_LENGTH) {
                  const ellipsis = "..."
                  const sliceLength = (MAX_NAME_LENGTH - ellipsis.length) / 2
                  displayName_test = `${displayName_test.slice(
                    0,
                    sliceLength
                  )}${ellipsis}${displayName_test.slice(-sliceLength)}`
                }
                if (displayName_partner.length > MAX_NAME_LENGTH) {
                  const ellipsis = "..."
                  const sliceLength = (MAX_NAME_LENGTH - ellipsis.length) / 2
                  displayName_partner = `${displayName_partner.slice(
                    0,
                    sliceLength
                  )}${ellipsis}${displayName_partner.slice(-sliceLength)}`
                }
                return (
                  <div key={key}>
                    <Card className="p-2" style={{ marginBottom: "5px" }}>
                      <div className="row" id={"pair" + file.pairId}>
                        <div className="col-md-8 d-flex">
                          <div
                            className="col-sm-2 ps-3"
                            style={{ paddingTop: "15px" }}
                          >
                            {" "}
                            {file.pairId}
                          </div>
                          <div
                            className="col-sm"
                            style={{ paddingTop: "10px" }}
                          >
                            <img
                              data-dz-thumbnail=""
                              height="30"
                              className=""
                              // alt={f.name}
                              src={excelimage}
                            />
                            {displayName_test}
                          </div>
                          <div
                            className="col-sm"
                            style={{ paddingTop: "10px" }}
                          >
                            <img
                              data-dz-thumbnail=""
                              height="30"
                              className=""
                              // alt={f.name}
                              src={excelimage}
                            />
                            {displayName_partner}
                          </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-end">
                          <div
                            className="col-sm col-md-8  d-flex justify-content-end"
                            style={{ paddingTop: "0px" }}
                          >
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Select Partner"
                              isSearchable={true}
                              name="Partners"
                              value={selectedPartners[file.pairId]}
                              onChange={value =>
                                partnerOnChangeHandler(value, file.pairId)
                              }
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  minWidth: '250px',
                                }),
                              }}
                              options={partners}
                            />
                          </div>
                          <div
                            className="align-middle col-sm d-flex justify-content-end pe-5"
                            style={{ paddingTop: "12px" }}
                            onClick={() => {
                              removeRow(file.pairId)
                              // dispatch(removeItemFromArray(file.pairId))
                              dispatch(removePairs(file.pairId))
                            }}
                          >
                            <img
                              data-dz-thumbnail=""
                              height="20"
                              className=""
                              // alt={f.name}
                              src={deleteimage}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )
              })}
            </div>
          </div>

          <Row style={{ marginTop: "50px"}}>
              <h5 style={{ paddingTop: "10px", fontWeight: "bold" }}>
                Recently Uploaded Batches{" "}
              </h5>
            <Col lg="12">
              <Card style={{ borderRadius: "10px" }}>
                <div
                  style={{ borderRadius: "10px" }}
                  className="table-responsive"
                  hidden={!recentUploads?.length > 0}
                >
                  <Table
                    style={{ backgroundColor: "white" }}
                    className="align-middle mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Source Data</th>
                        <th>Date Uploaded</th>
                        <th>Uploaded By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentUploads?.map(file => {
                        // console.log(file);
                        return (
                          <tr id={file.id} key={file.id}>
                            <td>
                              {/* <i className="fas fa-ad far fa-file-excel text-success"></i>{" "} */}
                              {file.sourcedata}
                            </td>

                            <td
                              style={{
                                borderLeft: "1px solid #dee2e6",
                                borderRight: "1px solid #dee2e6",
                              }}
                            >
                              {file.uploadedDate}
                            </td>

                            {/* Anushka */}
                            <td>{file.user}</td>

                            {/* <td>
                                <div className="d-flex gap-3">
                                  <Link className="text-danger" to="#">
                                    <i
                                      className="bx bx-x font-size-30"
                                      id="deletetooltip"
                                      onClick={() =>
                                        handleDeleteUser(file.id)
                                      }
                                    ></i>
                                  </Link>
                                  <Link className="text-success" to="#">
                                    <i
                                      className="bx bx-down-arrow-alt font-size-30"
                                      id="edittooltip"
                                      // onClick="window.open('file.doc')"
                                      onClick={() => handleUserClick(file.id)}
                                    ></i>
                                  </Link>
                                </div>
                              </td> */}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={modal_pipe_upload}>
        <ModalHeader>Upload Files</ModalHeader>
        <ModalBody>
          <LoadingSpinnerUpload />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        isOpen={modal_inspector}
        toggle={() => setModal_inspector(!modal_inspector)}
        style={{
          width: "80%",
          maxWidth: "none",
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            SMSC Inspector
          </h5>
          <button
            onClick={() => {
              setModal_inspector(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <SMSCInspector
            fileDrop={handleAcceptedServerFiles}
            close={() => setModal_inspector(!modal_inspector)}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default SourceUpload
