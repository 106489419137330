import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { get} from "lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { logoutUser } from "store/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {useSearchBatchesQuery, useDeleteBatchMutation} from "store/sourceUpload/sourceUpload.api"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css";
import store from "../../store/index";
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import { useGetCustomersQuery} from "store/customer/customer.api"
import { setCustomers } from "store/customer/customer.slice"
import { useGetOperatorsQuery } from "store/operator/operator.api"
import { setOperators } from "store/operator/operator.slice"
import { useGetTemplatesQuery } from "store/template/template.api"
import { setTemplates } from "store/template/template.slice"


const UploadFiles = () => {

  const [selectedTemplate, setTemplate] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedCustomer, setCustomer] = useState("")
  const [selectedDate, setTestDate] = useState("")
  const [sizePerPage, setSizePerPage] = useState(100)
  const [searchedBatches, setSearchedBatches] = useState([])

  const {data: searchedBatches_test,isFetching,isLoading,isError,refetch:refetchSearch} = useSearchBatchesQuery({
    customer: selectedCustomer,
    operator: selectedOperator,
    testDate: selectedDate,
    template: selectedTemplate,
    size: sizePerPage
  },
  {
    refetchOnMountOrArgChange: true,
  })
  const {data: customersNew, error: customersError, isFetching: customersFetching} = useGetCustomersQuery();
  const {customers: customer} = useSelector((state)=>state.customerNew);
  const {data : operatorsNew, error: operatorsError, isFetching: operatorsFetching} = useGetOperatorsQuery({
    customer_body : (selectedCustomer)?{customer_id: selectedCustomer}:''
    },
    {
      refetchOnMountOrArgChange: true,
    });
  const {operators: operator} = useSelector((state)=>state.operatorNew);
  const [deleteBatchMutation, {isFetching : deleteLoading}] = useDeleteBatchMutation();
  const {data : templatesNew, error: templatesError, isFetching: templatesFetching} = useGetTemplatesQuery();
  const {templates : template} = useSelector((state)=>state.templateNew);

  
  async function deleteProj (id) {
    Swal.fire({
      title: 'Do you want really want to Delete the Batch',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteBatchMutation(id).unwrap()
        .then((res)=>{
          Swal.fire('Delete Success!', '', 'success')
          setTimeout(() => {
            refetchSearch()
          }, 200);
        })
        .catch((err)=>{
          Swal.fire(`Delete Failed!\n${err}`, '', 'error')
          setTimeout(() => {
            refetchSearch()
          }, 200);
        })
        
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if(searchedBatches_test){
      const searchedBatches = searchedBatches_test.results.map((obj) => {
        return {
          id: obj.id,
          batch_id : obj.batch.id,
          batch_name: obj.batch.batch_name,
          uploadedDate: obj.batch.uploadedDate,
          customer: obj.customers_test.name,
          username: obj.batch.users_new?.name,
          hasReport: obj.batch.reports?.length>0?true:false,
          operator: obj.operator_test.operator_name+"  "+obj.operator_test.country,
        }
      })
      setSearchedBatches(searchedBatches)
    }
  }, [searchedBatches_test])
  
  useEffect(()=>{
    if(customersNew){
      let data = get(customersNew,'data',[]);
      dispatch(setCustomers(data.map(obj=>{
        return {id:obj.id,label:obj.name,value:obj.name}
      })))
    }
  },[customersNew])

  useEffect(()=>{
    // console.log("Triggered operators");
    if(operatorsNew){
      let data = get(operatorsNew,'message',[]);
      // console.log(data);
        const operator = data.map(obj=>{
          return {
            id:obj.id,
            label:obj.country+" "+obj.operator_name, 
            value:obj.country+" "+obj.operator_name
          }})
      operator.sort((a, b) => {
        // console.log(a,b);
        const countryA = a.label.split(' ')[0];
        const countryB = b.label.split(' ')[0];
      
        // Compare country names
        if (countryA < countryB) {
          return -1;
        } else if (countryA > countryB) {
          return 1;
        } else {
          return 0;
        }
      });
      // dispatch(setOperators(data.map(obj=>{
      //   return {id:obj.id,label:obj.name,value:obj.name}
      // })))
      dispatch(setOperators(operator))

    }
  },[operatorsNew])

  useEffect(()=>{
    if(templatesNew){
      let data = get(templatesNew,'templates',[]);
      dispatch(setTemplates(data))
    }
  },[templatesNew])

  useEffect(() => {
    if(templatesError){
      if(templatesError.status === 401){
        dispatch(logoutUser())
      }
    }
  }, [templatesError])
  


  const columns = [
    {
      dataField: "batch_name",
      text: "Batch Name",
      sort: true,
    },

    {
      dataField: "uploadedDate",
      text: "Date Uploaded",
      sort: true,
    },
    
    {
      dataField: "customer",
      text: "Customer",
      sort: true,
    },
    {
      dataField: "operator",
      text: "Operator",
      sort: true,
    },
    {
      dataField: "username",
      text: "Uploaded By",
      sort: true,
    },
    {
      dataField: "hasReport",
      text: "Has Report",
      sort: true,
      formatter: (cell) => {
        return (
          <div style={{ display: 'flex', marginLeft: 20}}>
            {cell ? 
              <i className="mdi mdi-check-circle text-success" style={{ fontSize: "20px" }}></i> : 
              <i className="mdi mdi-close-circle text-danger" style={{ fontSize: "20px" }}></i>
            }
          </div>
        );
      }
    },
    {
      dataField: "batch_id",
      text: "Delete",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            <Button
              type="button"
              color="danger"
              className="btn btn-danger waves-effect waves-light"
              onClick={() => {
                if(process.env.NODE_ENV === 'development'){
                // console.log("delete", row.batch_id)
                }
                deleteProj(row.batch_id)
              }}
            >
              <i className="mdi mdi-delete-forever"></i>
            </Button>
          </div>
        )
      }
    },
    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   sort: true,
    // },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]


  const pageOptions = {
    sizePerPage: 10,
    totalSize: searchedBatches.length, // replace later with size(customers),
    custom: true,
    
  }


  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Uploaded Batches</title>
        </MetaTags>
          <div className="container-fluid">.
          <Breadcrumbs title="Uploaded Batches" breadcrumbItem="Uploaded Batches" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={searchedBatches}

                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={searchedBatches}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div
                            style={{zIndex:"2", position:"relative"}}
                            >
                            <Row
                              className="mb-2"
                              style={{ paddingBottom: "20px"}}
                            >
                              <Col md="12" sm="12">
                                <Row>
                                  <Col md="2">
                                    <h5>Filters</h5>
                                  </Col>
                                  <Col md="2">
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="customer"
                                      // options={customer}
                                      options={[
                                        { value: '', label: 'No Customer Selected' },
                                        ...customer
                                      ]}
                                      onChange={e => {
                                        // console.log(e.id);
                                        if(e.id){
                                        setCustomer(e.id)
                                        setOperator('')
                                        // dispatch(getNewOperators({
                                        //   customer_id: e.id
                                        // }))
                                        }
                                        if(e.value === ''){
                                        setCustomer('')
                                        setOperator('')
                                        // dispatch(getNewOperators())
                                        }
                                      }}
                                      placeholder="Customer"
                                    />
                                  </Col>
                                  
                                  <Col md="2">
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="operator"
                                      value={operator.filter(obj=>obj.id===selectedOperator)}
                                      // options={operator}
                                      options={[
                                        { value: '', label: 'No Operator Selected' },
                                        ...operator
                                      ]}
                                      onChange={e => {
                                        if(e.id){
                                        setOperator(e.id)
                                        }
                                        if(e.value === ''){
                                        setOperator('')
                                        }
                                      }}
                                      placeholder="Operator"
                                    />
                                  </Col>
                                  <Col md="3" style={{
                                    paddingTop: "5px",
                                    display: "flex",
                                  }}>
                                    <Col md={4} style={{display:"flex"}}>
                                    <p style={{
                                      marginTop : 'auto',
                                      marginBottom : 'auto',
                                    }}>
                                      Uploaded Date : 
                                    </p>
                                    </Col>
                                    <Col md={8}>
                                    <input
                                      className="form-control"
                                      type="date"
                                      id="testDate"
                                      placeholder="Test Date"
                                      style={{ height: "38px" }}
                                      onChange={e => {
                                        setTestDate(e.target.value)
                                      }}
                                    />
                                    </Col>
                                  </Col>
                                  <Col md="2">
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="template"
                                      options={[
                                        { value: '', label: 'No Template Selected' },
                                        ...template
                                      ]}
                                      onChange={e => {
                                        if(e.id){
                                        setTemplate(e.id)
                                        }
                                        if(e.value === ''){
                                        setTemplate('')
                                        }
                                      }}
                                      placeholder="Template"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            </div>


                            <Row >
                                    
                                    <Col md="3" sm="12">
                                        <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                                            <div className="position-relative">
                                                <SearchBar
                                                    {...toolkitProps.searchProps}
                                                    style={{ height: "35px" }}
                                                />
                                                <i className="bx bx-search-alt search-icon sm-left-30" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    noDataIndication={()=>{
                                      // if(searchedBatches_loading===false){
                                      //   return <div style={{
                                      //     display: "flex",
                                      //     justifyContent: "center",
                                      //   }}>
                                      //     <LoadingSpinner/>
                                      //   </div>
                                      // }
                                      // else{
                                      //   return <div>No Data Found</div>
                                      // }
                                      if(isFetching===true || isLoading===true || deleteLoading===true){
                                        return <div style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                          <LoadingSpinner/>
                                        </div>
                                      }
                                      else{
                                        return <div>No Data Found</div>
                                      }
                                    }}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light custom-table-header"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone onSizePerPageChange={(page,sizePerPage)=>console.log(page)}
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UploadFiles
