// const HOST = 'http://localhost:4000';
const HOST = 'https://api_npt.amerck.com';
// const HOST = 'https://public-moments-laugh-123-231-110-216.loca.lt';
// const HOST = "http://192.168.1.8:4000"
// const HOST = 'https://api_gtc.amerck.com';
// const HOST = "https://qa_api_gtc.amerck.com"
// const HOST = "https://dev_api_gtc.amerck.com"
// const HOST = 'https://staging_api_gtc.amerck.com';

//Batch links

export default HOST + "/source-files/upload"
export const GET_SOURCE_FILES = HOST + "/source-files/get-uploaded-batch"
export const GET_UPLOADED_BATCHES = HOST + "/source-files/get-uploaded-batch"
export const SEARCH_UPLOADED_BATCHES = HOST + "/source-files/search"
export const _DELETE_BATCH = HOST + "/source-files/delete-batch/"

//DropDown links

export const _GET_CUSTOMERS = HOST + "/customers"
// export const _GET_PARTNERS = HOST + '/partners'
export const _GET_PARTNERS = HOST + "/newPartners/getPartner"
export const _GET_TEMPLATES = HOST + "/templates"
export const _GET_OPERATORS = HOST + "/operators"
// export const _GET_COUNTRIES = HOST + '/countries'

//report Links
export const GET_REPORTS = HOST + "/source-files/get-report/"
export const DELETE_REPORTS = HOST + "/source-files/delete-report/"
export const SEARCH_REPORTS = HOST + "/source-files/report-search/"
export const UPDATE_REPORTS = HOST + "/source-files/update-report"
export const UPDATE_KPI = HOST + "/source-files/update-kpi"
export const GENERATE_REPORT = HOST + "/source-files/report-generate"
export const REGEN_KPI = HOST + "/source-files/regen-kpi"
export const FILTER_REPORTS = HOST + "/source-files/filter-reports"
export const ADD_DUMMY_ROW = HOST + "/source-files/addDummyRows"

//Analytics Links
export const GET_NPT_RESULT_OVERVIEW = HOST + "/analytics/nptOverview"
export const GET_GATEKEEPER_FAKE_DLR = HOST + "/analytics/gatekeeperFakeDlr"
export const GET_GATEKEEPER_BYPASS = HOST + "/analytics/gatekeeperBypass"
export const GET_SENDERID_BYPASS = HOST + "/analytics/senderIDcount"
export const GET_BYPASS_ROUTES = HOST + "/analytics/bypassRoutes"

//Customer Analytics Links
export const GET_CUSTOMER_NPT_RESULT_OVERVIEW = HOST + "/analytics/customerNptOverview"
export const GET_CUSTOMER_SENDERID_BYPASS = HOST + "/analytics/customerSenderIDcount"
export const GET_CUSTOMER_BYPASS_ROUTES = HOST + "/analytics/customerBypassRoutes"

//KB links
//
export const _GET_KBS = HOST + "/kbs/getKBs"
export const _UPLOAD_KB = HOST + "/kbs/createKB"
export const _EDIT_KB = HOST + "/kbs/editKB"
export const _DELETE_KB = HOST + "/kbs/deleteKBs/"
export const _SELECTED_KB = HOST + "/kbs/selectedKBs/"

//customer Links

export const _GET_NEW_CUSTOMERS = HOST + "/newCustomers/getCustomers"
export const _UPLOAD_CUSTOMER = HOST + "/newCustomers/createCustomer"
export const _EDIT_CUSTOMER = HOST + "/newCustomers/editCustomer"
export const _DELETE_CUSTOMER = HOST + "/newCustomers/deleteCustomer/"
export const _SELECTED_CUSTOMER = HOST + "/newCustomers/selectedCustomer/"
export const _GET_COUNTRIES = HOST + "/newCustomers/countries"
export const _GET_NEWOPERATORS = HOST + "/newCustomers/operators"
export const _GET_NEWCOUNTRIES = HOST + "/newCustomers/countries"

//operator links

export const _GET_NEW_OPERATORS = HOST + "/newOperators/getOperators"
export const _UPLOAD_OPERATOR = HOST + "/newOperators/createOperator"
export const _EDIT_OPERATOR = HOST + "/newOperators/editOperator"
export const _DELETE_OPERATOR = HOST + "/newOperators/deleteOperator/"
export const _SELECTED_OPERATOR = HOST + "/newOperators/selectedOperator/"
export const _GET_COUNTRIES_OP = HOST + "/newOperators/countries"
export const _GET_OP_COUNTRIES = HOST + "/newOperators/getCountries"
export const _GET_OP_BY_COUNTRY = HOST + "/newOperators/getOperatorsByCountry"
export const _GET_GATEKEEPERS = HOST + "/newOperators/gatekeepers"
export const _GET_OP_BY_COUNTRY_AND_CUSTOMER = HOST + "/newOperators/getOperatorsByCountryAndCustomer"
export const _GET_CUSTOMER_OP_COUNTRIES = HOST + "/newOperators/getCustomerCountries"
export const _GET_CUSTOMER_OP_BY_COUNTRY = HOST + "/newOperators/getCustomersOperators"

//Country links

export const _GET_NEW_COUNTRY = HOST + "/newCountries/getCountries"
export const _UPLOAD_COUNTRY = HOST + "/newCountries/createCountry"
export const _EDIT_COUNTRY = HOST + "/newCountries/editCountry"
export const _DELETE_COUNTRY = HOST + "/newCountries/deleteCountry/"
export const _SELECTED_COUNTRY = HOST + "/newCountries/selectedCountry/"

//Partner links

export const _GET_NEW_PARTNERS = HOST + "/newPartners/getPartner"
export const _UPLOAD_PARTNER = HOST + "/newPartners/createPartner"
export const _EDIT_PARTNER = HOST + "/newPartners/editPartner"
export const _DELETE_PARTNER = HOST + "/newPartners/deletePartner/"
export const _GET_NEW_PAR_COUNTRIES = HOST + "/newPartners/countries"
export const _SELECTED_PARTNER = HOST + "/newPartners/selectedPartner/"

//auth

export const _LOGIN = HOST + "/auth/login"
export const _REGISTER = HOST + "/auth/register"

//history

export const _UPLOAD_REPORT = HOST + "/history/uploadReport"
export const _GET_RECENTLY_UPLOADED_REPORTS = HOST + "/history/recentReports"
export const _DELETE_HISTORICAL_REPORT = HOST + "/history/deleteReport/"
export const _UPDATE_REPORT = HOST + "/history/updateReport"
export const _UPDATE_KPI = HOST + "/history/updateKPI"
export const SHARE_REPORT = HOST + "/source-files/share"
//download

export const _DOWNLOAD_REPORT = HOST + "/downloads/reportDownload"

//users

export const _GET_USERS = HOST + "/users/getUsers"
export const _UPLOAD_USER = HOST + "/users/createUser"
export const _EDIT_USER = HOST + "/users/editUser"
export const _DELETE_USER = HOST + "/users/deleteUser"
export const _ACTIVATE_USER = HOST + "/users/activateUser"
export const _SELECTED_USER = HOST + "/users/selecteduser/"
export const _REMOVE_USER = HOST + "/users/removeUser"
export const _CHANGE_PASSWORD = HOST + "/users/changePassword"
export const _GET_NEW_USERS = HOST + "/newUsers/getAllUsers"
export const _GET_USER_BY_ID = HOST + "/newUsers/getUser"
export const _GET_ROLES = HOST + "/newUsers/getRoles"
export const _UPDATE_USER = HOST + "/newUsers/updateUser"
export const _CREATE_USER = HOST + "/newUsers/createUser"
export const _GET_CUSTOMERS_NEW = HOST + "/newUsers/getCustomers"
export const _GET_OPERATORS_NEW = HOST + "/newUsers/getOperators"
export const _CHANGE_PASSWORD_NEW = HOST + "/newUsers/changePassword"
export const _DELETE_USER_NEW = HOST + "/newUsers/deleteUser"
//templates

export const _GET_TEMPLATES_LIST = HOST + "/templates/getTemplates"
export const _UPLOAD_TEMPLATE = HOST + "/templates/createTemplate"
export const _EDIT_TEMPLATE = HOST + "/templates/editTemplate"
export const _DELETE_TEMPLATE = HOST + "/templates/deleteTemplate/"
export const _SELECTED_TEMPLATE = HOST + "/templates/selectedTemplate/"

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
// export const GET_CUSTOMERS = "/customers"
// export const ADD_NEW_CUSTOMER = "/add/customer"
// export const UPDATE_CUSTOMER = "/update/customer"
// export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

//customerPortal

export const PORTAL_GET_CUSTOMERS = HOST + "/customerPortal/getCustomers"
export const PORTAL_GET_OPERATORS = HOST + "/customerPortal/getOperators"
export const PORTAL_GET_KPI_DATA = HOST + "/customerPortal/getKPIDetails"
export const PORTAL_GET_LATEST_REPORT = HOST + "/customerPortal/getLatestReport"
export const PORTAL_DOWNLOAD_REPORT = HOST + "/customerPortal/downloadReport"

// smsc inspector

export const SMSC_INSPECTOR = HOST + "/inspector"

//otherChannels

export const _GET_OTHER_CHANNELS = HOST + "/otherChannels/getOtherChannels"
export const _GET_SELECTED_OTHER_CHANNELS = HOST + "/otherChannels/selectedOtherChannel"
export const _EDIT_OTHER_CHANNEL = HOST + "/otherChannels/editOtherChannel"
export const _DELETE_OTHER_CHANNEL = HOST + "/otherChannels/deleteOtherChannel"
export const _CREATE_OTHER_CHANNEL = HOST + "/otherChannels/createOtherChannel"


