import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"

import { NavLink, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import jwt from "jsonwebtoken"

// console.log(decodedToken.role);

const SidebarContent = props => {
  const ref = useRef()
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  // Usage
  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  useEffect(() => {
    const pathName = props.location.pathname
    new MetisMenu("#side-menu", {
      toggle: true,
    })
  }, [])

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      // new MetisMenu("#side-menu")

      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      // console.log(items);
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
        if ("/#" == items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      activateParentDropdown(items[0])
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    const pathName = props.location.pathname
    if (pathName == item.pathname) {
      item.classList.add("active")
    }
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      // parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement

            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active")
              // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {[2, 3, 5].includes(role) && (
              <li>
                <Link to="/" className="">
                  <i className="bx bx-add-to-queue"></i>
                  <span>{props.t("Upload Files")}</span>
                </Link>
              </li>
            )}
            {[2, 3, 5].includes(role) && (
              <li>
                <Link to="/upload-files" className="">
                  <i className="bx bx-upload"></i>
                  <span className="badge rounded-pill bg-success float-end"></span>
                  <span>{props.t("Uploaded Batches")}</span>
                </Link>
              </li>
            )}
            {[2, 3, 5].includes(role) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {[2, 3, 5].includes(role) && (
                    <li>
                      <NavLink to="/reports/generate">
                        {props.t("Generate")}
                      </NavLink>
                    </li>
                  )}
                  {[2, 3, 5].includes(role) && (
                    <li>
                      <NavLink to="/reports/edit">{props.t("Edit")}</NavLink>
                    </li>
                  )}
                  {[2, 3, 5].includes(role) && (
                    <li>
                      <NavLink to="/reports/history/import">
                        {props.t("Historical Data Import")}
                      </NavLink>
                    </li>
                  )}
                  <li>
                    {/* <NavLink to="/reports/history/view">{props.t("Imported Data")}</NavLink> */}
                  </li>
                </ul>
              </li>
            )}
            {[3, 4, 5].includes(role) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-bar-chart"></i>
                  <span>{props.t("Analytics")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {[3, 4, 5].includes(role) && (
                    <li>
                      <NavLink to="/analytics/nptresultoverview">
                        {props.t("NPT Result Overview")}
                      </NavLink>
                    </li>
                  )}
                  {[3, 4, 5].includes(role) && (
                    <li>
                      <NavLink to="/analytics/gatekeeperfakedlr">
                        {props.t("Gatekeeper Fake DLR")}
                      </NavLink>
                    </li>
                  )}
                  {[3, 4, 5].includes(role) && (
                    <li>
                      <NavLink to="/analytics/gatekeeperbypass">
                        {props.t("Gatekeeper Bypass")}
                      </NavLink>
                    </li>
                  )}
                  {[3, 4, 5].includes(role) && (
                    <li>
                      <NavLink to="/analytics/Bypassroute">
                        {props.t("Bypass Route")}
                      </NavLink>
                    </li>
                  )}
                  {/* {[3, 4, 5].includes(role) && (
                    <li>
                      <NavLink to="/analytics/otherchanneldelivery">
                        {props.t("Other Channel Delivery")}
                      </NavLink>
                    </li>
                  )} */}
                  {[3, 4, 5].includes(role) && (
                    <li>
                      <NavLink to="/analytics/senderidcount">
                        {props.t("Sender ID Count")}
                      </NavLink>
                    </li>
                  )}
                  <li>
                    {/* <NavLink to="/reports/history/view">{props.t("Imported Data")}</NavLink> */}
                  </li>
                </ul>
              </li>
            )}
            {[4].includes(role) && (
              <li>
                <Link to="/reports/edit">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
              </li>
            )}
            {[1, 2, 5].includes(role) && (
              <li>
                <Link to="/dashboard" className="">
                  {role === 1 ? (
                    <i class="bx bx-grid-alt"></i>
                  ) : (
                    <i class="bx bx-group"></i>
                  )}
                  <span className="badge rounded-pill bg-success float-end"></span>
                  {role === 1 ? (
                    <span>{props.t("Dashboard")}</span>
                  ) : (
                    <span>{props.t("Customer Portal")}</span>
                  )}
                </Link>
              </li>
            )}
            {[3, 5].includes(role) && (
              <li>
                <Link to="/smsc-inspector" className="">
                  <i class="bx bx-git-compare"></i>
                  <span className="badge rounded-pill bg-success float-end"></span>
                  <span>{props.t("SMSC Inspector")}</span>
                </Link>
              </li>
            )}
            {[1].includes(role) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-bar-chart"></i>
                  <span>{props.t("Analytics")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {[1].includes(role) && (
                    <li>
                      <NavLink to="/analytics/customernptresultoverview">
                        {props.t("NPT Result Overview")}
                      </NavLink>
                    </li>
                  )}
                  {[1].includes(role) && (
                    <li>
                      <NavLink to="/analytics/customerbypassroute">
                        {props.t("Bypass Route")}
                      </NavLink>
                    </li>
                  )}
                  {[1].includes(role) && (
                    <li>
                      <NavLink to="/analytics/customersenderidcount">
                        {props.t("Sender ID Count")}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
