import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: (builder) => ({
    shareReport: builder.mutation({
      query: (body) => ({
        url: urls.SHARE_REPORT,
        method: 'POST',
        body,
        headers: {
          auth: JSON.parse(localStorage.getItem('authUser')),
        },
        timeout: 4000,
      })
    }),
    addDummyRow: builder.mutation({
      query: (body) => ({
        url: urls.ADD_DUMMY_ROW,
        method: 'POST',
        body,
        headers: {
          auth: JSON.parse(localStorage.getItem('authUser')),
        },
        timeout: 4000,
      })
    }),
  }),
})

export const {useShareReportMutation, useAddDummyRowMutation} = reportsApi