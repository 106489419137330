import React, { useEffect, useState } from "react"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Row, Col, Card, CardBody, Button, CardTitle } from "reactstrap"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import store from "../../../../store/index"
import { useDispatch, useSelector } from "react-redux"
import {
  getReports,
  updateReportSuccess,
  updateReports,
  setReportsGetSuccess,
} from "store/old/reports/actions"
import jwt from "jsonwebtoken"
import Switch from "react-switch"
import filterFactory, {
  selectFilter,
  Comparator,
  textFilter,
} from "react-bootstrap-table2-filter"
import { useAddDummyRowMutation } from "store/reports/reports.api"

const products = [
  { id: 1, age: 25, qty: 1500, cost: 1000 },
  { id: 2, age: 34, qty: 1900, cost: 1300 },
  { id: 3, age: 67, qty: 1300, cost: 1300 },
  { id: 4, age: 23, qty: 1100, cost: 6400 },
  { id: 5, age: 78, qty: 1400, cost: 4000 },
]

const columns2 = [
  {
    dataField: "id",
    text: "ID",
    headerClasses: "id-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "country",
    text: "Country",
    headerClasses: "country-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "operator",
    text: "Operator",
    headerClasses: "operator-header",
    style: { minWidth: "150px" },
  },
  {
    dataField: "is_delivered",
    text: "Is Delivered",
    headerClasses: "is-delivered-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "delivery_timestamp",
    text: "Delivery Timestamp",
    headerClasses: "timestamp-header",
    style: { minWidth: "160px" },
  },
  {
    dataField: "delivery_duration",
    text: "Delivery Duration",
    headerClasses: "delivery-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "b_number",
    text: "B Number",
    headerClasses: "b_number-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "sender_id_type",
    text: "Sender Id Type",
    headerClasses: "sender_id_type-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "price",
    text: "Price",
    headerClasses: "price-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "sent_sender_id",
    text: "Send Sender Id",
    headerClasses: "sent_sender_id-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "received_sender_id",
    text: "Received Sender Id",
    headerClasses: "received_sender_id-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "is_sender_id_preserved",
    text: "Sender Presereved",
    headerClasses: "is_sender_id_preserved-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "smsc",
    text: "SMSC",
    headerClasses: "smsc-header",
    style: { minWidth: "150px" },
  },
  {
    dataField: "national_smsc",
    text: "Expected SMSC",
    headerClasses: "expected_sms-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "smsc_owner",
    text: "SMSC Owner",
    headerClasses: "smsc_owner-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "sent_text",
    text: "Sent Text",
    headerClasses: "sent_text-header",
    style: { minWidth: "150px" },
    filter: textFilter({
      placeholder: "Enter Text",
      className: "sent-filter",
      comparator: Comparator.LIKE,
      caseSensitive: false,
      delay: 400,
    }),
  },
  {
    dataField: "received_text",
    text: "Received Text",
    headerClasses: "received_text-header",
    style: { minWidth: "150px" },
  },
  {
    dataField: "is_text_preserved",
    text: "Text Presereved",
    headerClasses: "is_text_preserved-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "template_spoofing",
    text: "Template Spoofing",
    headerClasses: "gate-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "gate",
    text: "Gate",
    headerClasses: "gate-header",
    style: { minWidth: "100px" },
  },
  {
    dataField: "partner",
    text: "Partner",
    headerClasses: "gate-header",
    style: { minWidth: "100px" },
  },
  // {
  //   dataField:"createdAt",
  //   text:"Created At"
  // },
]

const notDeliveredTable = props => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role
  var temp = props.highlights.highlights
  const [highlights, setHighlights] = useState(temp)
  const [addDummyRow, { data, error, isLoading }] = useAddDummyRowMutation()

  const cellEdit =
    role !== 4
      ? cellEditFactory({
          mode: "dbclick",
          blurToSave: true,
          beforeSaveCell: (oldValue, newValue, row, column, done) => {
            setTimeout(() => {
              if (column.dataField === "delivery_timestamp") {
                const timestampPattern =
                  /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}\+\d{1,2}$/
                if (!timestampPattern.test(newValue)) {
                  toastr.error(
                    "Invalid timestamp format. Please use YYYY-MM-DD HH:mm:ss+n format"
                  )
                  done(false)
                  return
                }
                done()
              }
              else if (column.dataField === "delivery_duration") {
                const durationPattern = /^\d{2}:\d{2}:\d{2}$/
                if (!durationPattern.test(newValue)) {
                  toastr.error(
                    "Invalid duration format. Please use HH:mm:ss format"
                  )
                  done(false)
                  return
                }
                done()
              }
              else{
                done()
              }
            }, 10)
            return { async: true }
          },
          afterSaveCell: (oldValue, newValue, row, column) => {
            let column_dat = column?.dataField
            // console.log(row)
            if ((column_dat = "is_delivered")) {
              if (newValue == "yes" && oldValue == "no") {
                let testDataId = row.id
                let smscOwner_highlight = {
                  highlight_data: `${testDataId}-14`,
                }
                let recievedTextHighlight = {
                  highlight_data: `${testDataId}-16`,
                }
                let recieved_id = {
                  highlight_data: `${testDataId}-10`,
                }
                setHighlights([
                  ...highlights,
                  smscOwner_highlight,
                  recievedTextHighlight,
                  recieved_id,
                ])
              }
            }
          },
        })
      : undefined

  const reportID = useSelector(state => state.Reports.selectedReportId)
  const notdeliveredReports = props.notdeliveredReports
  const Reports = props.notdeliveredReports
  var reportData = Reports.length == 0 ? products : Reports.not_delivered
  var deliveredData = Reports.length == 0 ? products : Reports.delivered

  const dispatch = useDispatch()

  function showToast() {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: "5000",
      extendedTimeOut: "1000",
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: "500",
      hideDuration: "1000",
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    toastr.success("Values Updated", "Edit Successful")
  }

  async function sendEdits() {
    var formData = new FormData()

    //console.log(JSON.stringify(reportData));

    if (Reports.length != 0) {
      let report_temp = [...deliveredData, ...reportData]
      // console.log(report_temp);
      formData.append("data", JSON.stringify(report_temp))
      formData.append("highlights", JSON.stringify(highlights))
      formData.append("customer", "selectedCustomer")
      formData.append("report_id", JSON.stringify(reportID))
      dispatch(updateReports(formData))
      dispatch(setReportsGetSuccess(false))
      dispatch(updateReportSuccess(false))
      let editPromise = new Promise((resolve, reject) => {
        store.subscribe(() => {
          const updateSuccess = store.getState()
          const status = updateSuccess.Reports.updateSuccess
          if (status == true) {
            resolve("Success!")
          }
          if (status == "error") {
            reject("Error!")
          }
        })
      })
      await editPromise
        .then(async message => {
          // console.log("Report Edit success");
          showToast()
          dispatch(getReports({ id: reportID, template: "All" }))
          dispatch(updateReportSuccess(false))
          // await genKPI();
        })
        .catch(message => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          })
          dispatch(getReports(reportID))
          dispatch(updateReportSuccess(false))
        })
      // setTimeout(() => {
      //   showToast()
      //   dispatch(getReports(reportID))
      // }, 2000);
    }
  }
  async function addDummyRowFunc() {
    let bodyData = {
      report_id: reportID,
    }
    dispatch(updateReportSuccess(false))
    dispatch(setReportsGetSuccess(false))
    await addDummyRow(bodyData)
      .then(res => {
        // console.log(res)
        dispatch(getReports({ id: reportID, template: "All" }))
        dispatch(updateReportSuccess(false))
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonText: "OK",
        })
        dispatch(getReports(reportID))
        dispatch(updateReportSuccess(false))
      })
  }
  const [showHiddenColumns, setShowHiddenColumns] = useState(true)

  const toggleHiddenColumns = () => {
    setShowHiddenColumns(!showHiddenColumns)
  }

  const visibleColumns = showHiddenColumns
    ? columns2.filter(
        column =>
          !["id", "country", "operator", "b_number", "sender_id_type"].includes(
            column.dataField
          )
      )
    : columns2

  return (
    <React.Fragment>
      <div className="col-12" style={{ paddingTop: "5px", marginTop: "30px" }}>
        <div>
          {/* <Breadcrumbs title="Tables" breadcrumbItem="Editable Table" /> */}

          <Row>
            <Col>
              {/*  <CardTitle>NOT DELIVERED </CardTitle> */}
              <div className="modal-header d-flex justify-content-between">
                <div className="">
                  <h5>NOT DELIVERED</h5>
                </div>
                <div className="mt-3 d-flex">
                  <label>Hide Columns : &nbsp;</label>
                  <Switch
                    onChange={toggleHiddenColumns}
                    checked={showHiddenColumns}
                    id="columnToggle"
                    onColor="#8e292b"
                    offColor="#717273"
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          fontSize: 7,
                          color: "white",
                          paddingLeft: 2,
                        }}
                      >
                        Hidden
                      </div>
                    }
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          fontSize: 7,
                          color: "white",
                          paddingRight: 2,
                        }}
                      >
                        Visible
                      </div>
                    }
                    width={45}
                    height={18}
                  />
                  <Button
                    color="danger"
                    paddingTop="50px"
                    className="btn btn-custom1 md-position-absolute p-2 bd-highlight"
                    onClick={() => {
                      sendEdits()
                    }}
                    disabled={role === 4}
                  >
                    Save Edits
                  </Button>
                  {/* <Button
                    color="danger"
                    paddingTop="50px"
                    className="btn btn-custom1 md-position-absolute p-2 bd-highlight"
                    onClick={() => {
                      addDummyRowFunc()
                    }}
                  >
                    Add Row
                  </Button> */}
                </div>
              </div>
              <div className="table-responsive scrollable1 table_shadow_div reportdata_table reportdata_table12">
                <BootstrapTable
                  key={showHiddenColumns ? "visible" : "hidden"}
                  keyField="id"
                  data={reportData}
                  columns={visibleColumns}
                  cellEdit={cellEdit}
                  filter={filterFactory()}
                />
              </div>
              <br />
              <Col className="position-relative ">
                <Button
                  color="danger"
                  paddingTop="50px"
                  className="btn btn-custom1 md-position-absolute ms-auto p-2 bd-highlight"
                  onClick={() => {
                    sendEdits()
                  }}
                  disabled={role === 4}
                >
                  Save Edits
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default notDeliveredTable
