import React from "react"

import ReactApexChart from "react-apexcharts"

const chartapex = ({series,dates}) => {
  // const series = [
  //   {
  //     name: "Delivery of Routes Above MTR(%)",
  //     data: [58.33, 63.33, 66.67, 63.33],
  //   },
  //   {
  //     name: "Delivery Ratio of Routes Under MTR",
  //     data: [20.38, 17.94, 14.8, 17.35],
  //   },
  //   {
  //     name: "FakeDLR Ratio of Routes Above MTR",
  //     data: [21.67, 16.67, 13.33, 16.67],
  //   },
  //   {
  //     name: "FakeDLR Ratio of Routes Below MTR",
  //     data: [39.62, 42.06, 45.2, 42.65],
  //   },
  // ]

  let maxYValue = 0

  if (series) {
    for (var Obj of series) {
        if (Obj.name=="Delivery ratio - routes above MTR (%)") {
          for( var data1 of Obj.data){
            if(data1 > maxYValue) {
              maxYValue = data1
              
            }
          }
        }
        if (Obj.name=="Delivery ratio - routes below MTR (%)") {
          for( var data2 of Obj.data){
            if(data2 > maxYValue) {
              maxYValue = data2
              
            }
          }
        }
        if (Obj.name=="Fake DLR ratio - routes above MTR (%)") {
          for( var data3 of Obj.data){
            if(data3 > maxYValue) {
              maxYValue = data3
              
            }
          }
        }
        if (Obj.name=="Fake DLR ratio - routes below MTR (%)") {
          for( var data4 of Obj.data){
            if(data4 > maxYValue) {
              maxYValue = data4
              
            }
          }
        }
      // console.log("Max value out of all MTR data : " + maxYValue);
    }
  }
  //const kpiData = props.chartData?props.chartData:[];
  //var dataCheck = kpiData.length==0?series:kpiData.mtr_chart_data;  /* deliverd, not deliverd, kpi prop have tale length ? */
  //var chartData = dataCheck?.length==0?series:dataCheck; /* kpireports have tale length ? */

  const options = {
    chart: { 
      zoom: { enabled: false }, // Disabling zoom
      toolbar: { show: false } // Hiding toolbar
    },
    colors: ["#1DAD61", "#FB4735", "#1DAD61", "#FB4735"], // Setting line colors

    dataLabels: { enabled: false }, // Hiding data labels on points

    stroke: { 
      width: [3, 3, 3, 3], // Line thickness
      curve: "smooth", // Making the lines smooth
      dashArray: [0, 0, 4, 4] // Making last two series dotted (Fake DLR ratios)
    },

    title: { text: "MTR Ratios", align: "left" }, // Chart title

    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 }, 
      borderColor: "#f1f1f1", // Grid border color
    },

    xaxis: {
      categories: dates, // Setting X-axis labels (Months)
      title: { text: "Month" }, // X-axis title
    },

    yaxis: {
      title: { text: "%" }, // Y-axis title
      labels: {
        formatter: function (value) {
          return value ? value.toFixed(2) + "%" : "0%"; // Formatting Y-axis labels
        },
      },
      min: 0.0, // Minimum value for Y-axis
      max: maxYValue + maxYValue / 10, // Setting max value dynamically
    },

    legend: {
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 5,
      offsetX: -15,
      show: true,
      markers: {
      customHTML: [
        function () {
          return '<div style="width: 55px;  height: 3px; background-color: #1DAD61; margin-right:2px;"></div>';
        }, // Solid green line

        function () {
          return '<div style="width: 55px; height: 3px; border-top: 2px dashed #1DAD61; margin-right:2px;"></div>';
        }, // Dashed green line
        
        function () {
          return '<div style="width: 55px; height: 3px; background-color: #FB4735; margin-right:2px;"></div>';
        }, // Solid red line

        function () {
          return '<div style="width: 55px; height: 3px; border-top: 2px dashed #FB4735; margin-right:2px;"></div>';
        }, // Dashed red line
      ],
    },
    },

    responsive: [
      {
        breakpoint: 600,
        options: { 
          chart: { toolbar: { show: false } }, 
          legend: { show: false } // Hiding legend on smaller screens
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      //   series={props.chartData?props.chartData:series}
      series={series}
      type="line"
      height="380"
      // className="apexcharts-canvas apexchartscq310u9c apexcharts-theme-light"
    />
  )
}

export default chartapex