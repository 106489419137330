import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"
import { Link, useLocation } from "react-router-dom"
import jwt from "jsonwebtoken"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"
import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logonew.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"
import customerImg from "../../assets/images/brands/customer.png"
import operatorImg from "../../assets/images/brands/operator.png"
import partnerImg from "../../assets/images/brands/partner.png"
import otherchannelImg from "../../assets/images/otherChannel.png"
import templateImg from "../../assets/images/brands/template.png"
import smscImg from "../../assets/images/brands/smsc.png"
import countryImg from "../../assets/images/brands/country.png"
import usersImg from "../../assets/images/brands/users.png"
import formulaImg from "../../assets/images/brands/formulas.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import NavbarContent from "./NavbarContent"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }
  // Usage
  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }
  useEffect(() => {
    if (role == 1) {
      var body = document.body
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }, [role])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <Link to='/'>
              <img
                className="logo-main"
                style={{ paddingLeft: "15px" }}
                src={logo}
                alt="gtc logo"
                width={162}
                height={55}
              />
            </Link>
          </div>
          <div>
            <NavbarContent />
          </div>
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            {decodedToken.role != 1 && (
              <Dropdown
                className="d-lg-inline-block ms-1"
                isOpen={socialDrp}
                toggle={() => {
                  setsocialDrp(!socialDrp)
                }}
              >
                <DropdownToggle
                  className="btn header-item noti-icon "
                  caret
                  tag="button"
                >
                  <div style={{ display: "flex" }}>
                    <i className="bx bx-cog" />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <Link
                          to="/operators"
                          onClick={() => {
                            setsocialDrp(false)
                          }}
                          className={
                            location.pathname === "/operators"
                              ? "dropdown-icon-item top-header-visited"
                              : "dropdown-icon-item"
                          }
                        >
                          <img src={operatorImg} alt="operator" />
                          <span>{props.t("Operators")}</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link
                          to="/customers"
                          onClick={() => {
                            setsocialDrp(false)
                          }}
                          className={
                            location.pathname === "/customers"
                              ? "dropdown-icon-item top-header-visited"
                              : "dropdown-icon-item"
                          }
                        >
                          <img src={customerImg} alt="customer" />
                          <span>{props.t("Customers")}</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link
                          to="/settings/country"
                          onClick={() => {
                            setsocialDrp(false)
                          }}
                          className={
                            location.pathname === "/settings/country"
                              ? "dropdown-icon-item top-header-visited"
                              : "dropdown-icon-item"
                          }
                        >
                          <img src={countryImg} alt="country" />
                          <span>{props.t("Countries")}</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link
                          to="/settings/knowledge-base"
                          onClick={() => {
                            setsocialDrp(false)
                          }}
                          className={
                            location.pathname === "/settings/knowledge-base"
                              ? "dropdown-icon-item top-header-visited"
                              : "dropdown-icon-item"
                          }
                        >
                          <img src={smscImg} alt="smscs" />
                          <span>{props.t("SMSCs")}</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link
                          to="/settings/templates"
                          onClick={() => {
                            setsocialDrp(false)
                          }}
                          className={
                            location.pathname === "/settings/templates"
                              ? "dropdown-icon-item top-header-visited"
                              : "dropdown-icon-item"
                          }
                        >
                          <img src={templateImg} alt="templates" />
                          <span>{props.t("Templates")}</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link
                          to="/partners"
                          onClick={() => {
                            setsocialDrp(false)
                          }}
                          className={
                            location.pathname === "/partners"
                              ? "dropdown-icon-item top-header-visited"
                              : "dropdown-icon-item"
                          }
                        >
                          <img src={partnerImg} alt="partner" />
                          <span>{props.t("Partners")}</span>
                        </Link>
                      </Col>
                    </Row>
                    {[2, 5].includes(decodedToken.role) ? (
                      <Row className="no-gutters">
                        <Col>
                          <Link
                            to="/settings/otherchannel"
                            onClick={() => {
                              setsocialDrp(false)
                            }}
                            className={
                              location.pathname === "/settings/otherchannel"
                                ? "dropdown-icon-item top-header-visited"
                                : "dropdown-icon-item"
                            }
                          >
                            <img src={otherchannelImg} alt="KPI" />
                            <span>{props.t("Other Channel")}</span>
                          </Link>
                        </Col>
                        <Col>
                          <Link
                            to="/settings/users"
                            onClick={() => {
                              setsocialDrp(false)
                            }}
                            className={
                              location.pathname === "/settings/users"
                                ? "dropdown-icon-item top-header-visited"
                                : "dropdown-icon-item"
                            }
                          >
                            {/* <i className="mdi mdi-radio-tower"></i> */}
                            <img src={usersImg} alt="users" />
                            <span>{props.t("Users")}</span>
                          </Link>
                        </Col>
                        {/* <Col>
                      <Link to="/settings/formulas" onClick={()=>{setsocialDrp(false)}} className={location.pathname==="/settings/users"?"dropdown-icon-item top-header-visited":"dropdown-icon-item"}>
                      <i className="mdi mdi-radio-tower"></i>
                        <img src={formulaImg} alt="users"/>
                        <span>{props.t("Formulas")}</span>
                      </Link>
                    </Col> */}
                      </Row>
                    ) : (
                      <Row className="no-gutters">
                        <Col>
                          <Link
                            to="/settings/otherchannel"
                            onClick={() => {
                              setsocialDrp(false)
                            }}
                            className={
                              location.pathname === "/settings/otherchannel"
                                ? "dropdown-icon-item top-header-visited"
                                : "dropdown-icon-item"
                            }
                          >
                            <img src={otherchannelImg} alt="KPI" />
                            <span>{props.t("Other Channel")}</span>
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </div>
                </DropdownMenu>
              </Dropdown>
            )}

            {/* <NotificationDropdown /> */}
            <ProfileMenu />

            {/* <div
              onClick={toggleTopDrawer} disabled={open}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
